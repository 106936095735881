import React, {memo, useEffect, useState} from 'react';
import styles from "./switches.module.sass";
import {Switch} from "antd";
import {
    choiceOfPlatformAC,
    selectCategoryAC,
    selectChartAC,
    selectMarketPlacesCheckBoxAC,
    selectSOVAC,
    selectSuperMarketsCheckBoxAC,
    switchCategoriesAC,
    switchSelloffAC,
    switchSharesAC,
    switchSOVAC
} from "../../../redux/optionsReducer";
import {SOVForMarketPlaces, SOVForSuperMarkets} from "../../../common/optionsForSwitches";
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {
    choiceOfPlatform,
    selectCategory,
    selectChart,
    selectMarketPlaces,
    selectSharesChecked, selectSuperMarkets, selectSwitchShares, selectTableChartCategories
} from "../../../common/selectors";
import {tableChartCategoriesAC} from "../../../redux/categoriesReducer";

export const Switches = memo(() => {

    const dispatch = useAppDispatch()
    const category = useAppSelector(selectCategory)
    const checkedShares = useAppSelector(selectSharesChecked)
    const platform = useAppSelector(choiceOfPlatform)
    const chart = useAppSelector(selectChart)
    const mps = useAppSelector(selectMarketPlaces)
    const spm = useAppSelector(selectSuperMarkets)
    const switchShares = useAppSelector(selectSwitchShares)
    const tableCharts = useAppSelector(selectTableChartCategories)

    const [disabledStocks, setDisabledStocks] = useState(false)
    const [disabledSOV, setDisabledSOV] = useState(false)
    const [disabledSales, setDisabledSales] = useState(false)

    useEffect(() => {
       if( platform === 'categories') {
           setDisabledStocks(true)
           setDisabledSOV(true)
           setDisabledSales(true)
       }
       if(platform === 'stocks'&&chart === 'Каталог') {
           setDisabledStocks(true)
           setDisabledSOV(true)
           setDisabledSales(false)
       }
        if(platform === 'stocks'&&chart === 'Каталог1') {
            setDisabledStocks(true)
            setDisabledSOV(true)
            setDisabledSales(false)
        }
        if(platform === 'stocks'&&(chart === 'Рынок, темпы' || chart === 'Рынок, руб')) {
            setDisabledStocks(false)
            setDisabledSOV(false)
            setDisabledSales(true)
        }
        if(platform === 'stocks'&&(chart === 'АКБ %' || chart === 'АКБ')) {
            setDisabledStocks(false)
            setDisabledSOV(true)
            setDisabledSales(true)
        }
    }, [platform, chart]);
    const onChangeSharesSwitch = (checked: boolean) => {
        dispatch(switchSharesAC(checked))
        if (checked) {
            dispatch(selectMarketPlacesCheckBoxAC(checkedShares))
            dispatch(selectSuperMarketsCheckBoxAC(checkedShares))
        }
        if (!checked) {
            dispatch(selectMarketPlacesCheckBoxAC([]))
            dispatch(selectSuperMarketsCheckBoxAC([]))
        }
    };

    const onChangeSOVSwitch = (checked: boolean) => {
        dispatch(switchSOVAC(checked))
        if (category === 'marketplaces' && checked) {
            dispatch(selectSOVAC(SOVForMarketPlaces))
        }
        if (category === 'supermarkets' && checked) {
            dispatch(selectSOVAC(SOVForSuperMarkets))
        }
        if (!checked) {
            dispatch(selectSOVAC([]))
        }
    };

    const onChangeSwitch = (checked: boolean) => {
        //setDisabled(checked)
        dispatch(switchCategoriesAC(checked))
        dispatch(choiceOfPlatformAC(checked ? 'categories' : 'stocks'))
        dispatch(selectChartAC(checked ? 'Категории' : 'Рынок, темпы'))
        dispatch(selectCategoryAC('marketplaces'))
        dispatch(tableChartCategoriesAC(''))
    };

    const onChangeSelloffSwitch = (checked: boolean) => {
        dispatch(switchSelloffAC(checked))
    }

    return (
        <div className={styles.switches}>
            <div className={styles.switches_items}>
                <div style={{color: 'white', opacity: disabledStocks ? .5 : 1}}>Акции</div>
                <Switch defaultChecked disabled={disabledStocks} checked={switchShares} onChange={onChangeSharesSwitch}/>
            </div>
            <div className={styles.switches_items}>
                <div style={{color: 'white', opacity: disabledSOV ? .5 : 1}}>SOV</div>
                <Switch defaultChecked={false} disabled={disabledSOV} onChange={onChangeSOVSwitch}/>
            </div>
            <div className={styles.switches_items}>
                <div style={{color: 'white', opacity: disabledSales ? .5 : 1}}>Распродажи</div>
                <Switch defaultChecked={false} disabled={disabledSales} onChange={onChangeSelloffSwitch}/>
            </div>
            {/*<div className={styles.switches_items}>
                <div style={{color: 'white'}}>Категории</div>
                <Switch defaultChecked={false} onChange={onChangeSwitch}/>
            </div>*/}
        </div>
    );
});

