import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../redux/store";
import {
    selectCategory, selectChart,
    selectDataApi, selectDataForCatalog,
    selectMaxMarket,
    selectMaxOther,
    selectMaxSOV, selectMinMarket,
    selectMinOther,
    selectMinSOV, selectSelloffChecked, selectSwitchSOV, selectTime, visiblePDF
} from "../common/selectors";
import {setValueChartsAC, setYaxisAC} from "../redux/optionsReducer";
import {dataType, YaxisType} from "../common/types";

export const ValuesCharts = () => {

    const data = useAppSelector(selectDataApi)

    /*const maxSOV = useAppSelector(selectMaxSOV)
    const minSOV = useAppSelector(selectMinSOV)
    const maxOther = useAppSelector(selectMaxOther)
    const minOther = useAppSelector(selectMinOther)
    const maxMarket = useAppSelector(selectMaxMarket)
    const minMarket = useAppSelector(selectMinMarket)*/

    const dataForCatalog = useAppSelector(selectDataForCatalog)
    const category = useAppSelector(selectCategory)
    const chart = useAppSelector(selectChart)
    const time = useAppSelector(selectTime)
    const visible = useAppSelector(visiblePDF)
    const SOV = useAppSelector(selectSwitchSOV)
    const sell = useAppSelector(selectSelloffChecked)


    const dispatch = useAppDispatch()


    let numbMarket: number[] = []
    let numbSOV: number[] = []
    let numbOther: number[] = []
    let numbSell:number[] = []
    let numbCat:number[] = []


    data.map((el) => el.name === 'Рынок' && el.data.forEach((item: number[]) => numbMarket.push(item[1])))
    data.map((el) => el.name.includes('SOV') && el.data.forEach((item: number[]) => numbSOV.push(item[1])))
    data.map((el) => el.name !== 'Рынок' && !el.name.includes('SOV') && el.data.forEach((item: number[]) => numbOther.push(item[1])))
    dataForCatalog.map((el) => el.type === undefined && el.data.forEach((item: number[]) => numbSell.push(item[1])))
    dataForCatalog.map((el) => el.type === 'line' && el.data.forEach((item: number[]) => numbCat.push(item[1])))


    const maxSOV = Math.max(...numbSOV) + 1
    const minSOV = Math.min(...numbSOV) + 1
    const maxMarket = Math.max(...numbMarket)
    const minMarket = Math.min(...numbMarket)
    const maxOther = Math.max(...numbOther)
    const minOther = Math.min(...numbOther)
    const maxSell = Math.max(...numbSell)
    const maxCat = Math.max(...numbCat)


    const yaxisVariable: YaxisType[] = []

    const getYaxisChart = (change: number) => {
        data.forEach((el: dataType) => {
                if (el.name === 'Рынок') {
                    yaxisVariable.push({seriesName: el.name, show: false, min: maxMarket! - change, max: maxMarket! + 5})
                }
                if (el.name !== 'Рынок' && !el.name.includes('SOV')) {
                    yaxisVariable.push({
                        seriesName: el.name,
                        show: false,
                        min: SOV ? (minOther! >= 0 ? -maxOther! / 2 : -maxOther! / 2 + minOther! * 1.5) : (minOther! >= 0 ? 0 : minOther!),
                        max: maxOther! * 1.6
                    })
                }
                if (el.name.includes('SOV')) {
                    yaxisVariable.push({seriesName: el.name, show: false, min: 0, max: maxSOV! * 5})
                }

            }
        )
        return dispatch(setYaxisAC(yaxisVariable))
    }

    const getYaxisCatalog = () => {
        dataForCatalog.forEach((el) => {

            if (el.type === undefined) {
                yaxisVariable.push({seriesName: el.name, show: false, min: 0, max: maxSell*5})
            } else {
                yaxisVariable.push({seriesName: el.name, show: false, min: sell?-maxCat/3:0, max: maxCat})
            }

        })
        return dispatch(setYaxisAC(yaxisVariable))
    }

    const getYaxisMarket = (currentChart: string, currentCategory: string) => {
        if (currentChart === 'Рынок, темпы') {
            getYaxisChart(400)
        }
        if (currentChart === 'Рынок, руб' && currentCategory === 'marketplaces') {
            getYaxisChart(500)
        }
        if (currentChart === 'Рынок, руб' && currentCategory === 'supermarkets') {
            getYaxisChart(200)
        }
        if (currentChart === 'Каталог') {
            getYaxisCatalog()
        }

    }



    useEffect(() => {
        getYaxisMarket(chart, category)
        dispatch(setValueChartsAC(
            {
                maxSOV,
                minSOV,
                maxOther,
                minOther,
                maxMarket,
                minMarket,
            }
        ))
    },[dispatch, data])


    return (
        <div>

        </div>
    );
};

