import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {DataResType, dataType, SaleType} from "../common/types";
import {AppRootStateType} from "./store";
import {loadingCategoriesAC, setErrorAC} from "./authReducer";
import {dataApi} from "../api/dataApi";
import {AxiosError} from "axios";
import dayjs from "dayjs";
import {orderData} from "../common/orderData";

type InitialStateType = {
    data: dataType[],
    dataPercent: dataType[]
}

export const fetchDataSale = createAsyncThunk<any, any, { state: AppRootStateType }>('fetchDataSale', async (param: any,  {dispatch, rejectWithValue, getState}) => {
    dispatch(loadingCategoriesAC('loading'))
    try {
        const {options} = getState()
        const {categories} = getState()
        const res = await dataApi.getDataSale(param)
        const cat = await dataApi.getDataCategories({from_date: param.from_date, to_date: param.to_date, table_category: param.gl_category})

        const date = (arg: string) => dayjs(arg).valueOf()

        const dataCatSales = res.data.map((el: any) => ({...el, gl_cat_count: 0}))
        dataCatSales.map((el: any) => {
            cat.data.forEach((item: any) => {
                if (el.spm ===item.spm && (date(item.date_parse) < date(el.to_date) && date(item.date_parse) > date(el.get_date_row))) {
                    el.gl_cat_count +=  item.count_products
                }
            })
        })

        const dataGlob = dataCatSales.map((el: any) => ({...el, gl_percent: (el.count_products/el.gl_cat_count)*100}))
        const dataOrder: any[] = []
        orderData.forEach((el) => {
            dataGlob.forEach((item:SaleType) => {
                return item.spm === el ? dataOrder.push(item):item
            })
        })

        //const data: SaleType[] = res.data.map((el:SaleType) => ...el, middleDate: dayjs(el.get_date_row).valueOf() + 30240000)
        const data = dataOrder.sort((a: SaleType, b: SaleType) => dayjs(a.get_date_row).valueOf() - dayjs(b.get_date_row).valueOf())
        //data.map((el:SaleType) => ({...el,middleDate: dayjs(el.get_date_row).valueOf() + 30240000}))
        let dataName:string[] = []
        data.forEach((el: SaleType) => !dataName.includes(el.spm) && dataName.push(el.spm))

        data.forEach((el:SaleType) => {
            dataName.forEach((item) => {

            })
        })

        let datesSale: {name: string, date: number[]}[] = []
        dataName.forEach((el) => datesSale.push({name: el, date: []}))

        data.forEach((el: SaleType) => {
            datesSale.map((item) => {
               return !item.date.includes(dayjs(el.get_date_row).valueOf()) && item.date.push(dayjs(el.get_date_row).valueOf())
            })
        })

        let dataForCharts:dataType[] = dataName.map((el) => {
            return {name: el, data: []}
        })

        let dataForSecondCharts:dataType[] = dataName.map((el) => {
            return {name: el, data: []}
        })

        dataForCharts.map((el) => {
            datesSale.forEach((item) => {
               return item.name === el.name ? item.date.forEach((value) => el.data.push([value, 0])) : item
            })
        })

        dataForSecondCharts.map((el) => {
            datesSale.forEach((item) => {
                return item.name === el.name ? item.date.forEach((value) => el.data.push([value, 0])) : item
            })
        })

        categories.tableChartCategories ?
            data.filter((el: any) => el.gl_cat_count > 0).forEach((el: SaleType) => {
                dataForCharts.forEach((item) => {
                    if (el.spm === item.name) {
                        return item.data.map((value) => value[0] === dayjs(el.get_date_row).valueOf() ? value[1] += +el.count_products : value)
                    }
                })
                dataForSecondCharts.forEach((item) => {
                    if (el.spm === item.name) {
                        return item.data.map((value) => value[0] === dayjs(el.get_date_row).valueOf() ? value[1] += el.gl_percent  : value)
                    }
                })

            })
        :
        data.forEach((el: SaleType) => {
            dataForCharts.forEach((item) => {
                if (el.spm === item.name) {
                    return item.data.map((value) => value[0] === dayjs(el.get_date_row).valueOf() ? value[1] += +el.count_products : value)
                }
            })
            dataForSecondCharts.forEach((item) => {
                if (el.spm === item.name) {
                    return item.data.map((value) => value[0] === dayjs(el.get_date_row).valueOf() ? value[1] +=  +el.average_percent : value)
                }
            })

        })

        const filteredDate: dataType[] = dataForSecondCharts.filter((el) => options.marketPlacesNames.includes(el.name))

        /*filteredDate.map((el) => el.data.map((item) => {(
            item[0] += 313200000,
            item[1] > 100 ? 100 : item[1]
        )}))*/

        filteredDate.map((el) => {
            el.data.map((item) => (
                item[0] += 313200000,
                item[1]= item[1] > 100 ? 100 : item[1]
            ))
        })



        dispatch(loadingCategoriesAC('succeeded'))
        console.log(filteredDate)
        return [filteredDate, dataForSecondCharts]
    }catch(err) {
        dispatch(loadingCategoriesAC('failed'))
        const error = err as AxiosError

        if (!error.response) {
            throw err
        }
        dispatch(setErrorAC(error.response.status))
        return rejectWithValue(error.response)
    }
})


export const slice = createSlice({
    name: 'sale',
    initialState: {
        data: [],
        dataPercent: []
    } as InitialStateType,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDataSale.fulfilled, (state, action) => {
                state.data = action.payload[0]
                //state.dataPercent = action.payload[1]
            })

    }
},
    )

export const saleReducer = slice.reducer