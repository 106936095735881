import React, {memo} from 'react';
import Checkbox from "antd/es/checkbox";
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {CheckboxValueType} from "antd/es/checkbox/Group";

import styles from './superMarkets.module.sass'
import {selectSuperMarketsCheckBoxAC, sharesCheckedAC} from "../../../redux/optionsReducer";
import {selectDataName, selectSuperMarkets} from "../../../common/selectors";
import {second, superMarketsOptions} from "../../../common/optionsForCheckBoxes";


export const SuperMarkets = memo(() => {

    const dispatch = useAppDispatch()
    const superMarkets = useAppSelector(selectSuperMarkets)
    const dataNames = useAppSelector(selectDataName)

    const onChange = (checkedValues: CheckboxValueType[]) => {
        dispatch(selectSuperMarketsCheckBoxAC(checkedValues) )
        dispatch(sharesCheckedAC(checkedValues))
    };

    let filter: string[] = dataNames.filter((el) => el.indexOf('SOV') === -1)
    let filteredSpmOptions = superMarketsOptions.filter((el) => filter.includes(el.value))

    return (
        <div  className={styles.main} >
            <Checkbox.Group onChange={onChange} className={styles.checkboxGroup} defaultValue={superMarkets} >
                <div className={styles.checkbox}>
                    {filteredSpmOptions.map((el, index) => <Checkbox key={index} style={second.includes(el.value)?{color: '#808080'}: {color: 'white'}}  value={el.value} className={styles.item}>
                                                                                <div className={styles.label} >{el.label}</div>
                                                                            </Checkbox>)}
                </div>
            </Checkbox.Group>
        </div>
    );
});

