import React, {memo} from 'react';
import styles from "./chartsButtons.module.sass";
import {buttonsData, buttonsDataCategories, buttonsDataPrice} from "../../../../common/optionsForChartsButton";
import {ButtonsDataType} from "../../../../common/types";
import {Button} from "antd";
import {
    selectChartAC,
    selectMarketPlacesCheckBoxAC,
    selectSuperMarketsCheckBoxAC,
    sharesCheckedAC, switchSharesAC
} from "../../../../redux/optionsReducer";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {choiceOfPlatform, selectCategory, selectChart, selectSwitchCategories} from "../../../../common/selectors";
import {defValueForMarketPlaces, defValueForSuperMarkets} from "../../../../common/optionsForCheckBoxes";
import {tableChartCategoriesAC} from "../../../../redux/categoriesReducer";

export const ChartsButtons = memo(() => {

    const dispatch = useAppDispatch()
    const chart = useAppSelector(selectChart)
    const categories = useAppSelector(selectSwitchCategories)
    const category = useAppSelector(selectCategory)
    const platform = useAppSelector(choiceOfPlatform)

    const onClickHandler = (name: string) => {
        dispatch(selectChartAC(name))
        dispatch(switchSharesAC(true))
        dispatch(tableChartCategoriesAC(''))
        if (category === 'marketplaces') {
            dispatch(selectMarketPlacesCheckBoxAC(defValueForMarketPlaces))
            dispatch(sharesCheckedAC(defValueForMarketPlaces ))
        }
        if (category === 'supermarkets') {
            dispatch(selectSuperMarketsCheckBoxAC(defValueForSuperMarkets))
            dispatch(sharesCheckedAC(defValueForSuperMarkets ))
        }



    }

    const mappingButtons = (buttons: ButtonsDataType) => buttons.map((el, index) => <Button key={index} disabled={category === 'supermarkets'&&el.value === 'Каталог'}
                                                                                            className={styles.button}
                                                                                            onClick={() => onClickHandler(el.value)}
                                                                                            type={chart !== el.value ? 'primary' : 'default'}>{el.label}</Button>)
    return (
        <div className={styles.buttons}>
            {mappingButtons(categories ? buttonsDataCategories : (platform === 'price' ? buttonsDataPrice : buttonsData))}
        </div>
    );
});

