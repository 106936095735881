import React from 'react';

import styles from './tableComponent.module.sass'
import {EmptyComponent} from "../../../common/empty";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import cn from "classnames";


type TableComponentPropsType = {
    name: string
    titleTable: {[index: string]:string}
    tableContent: any
    filter: string[]
    zoom: undefined[] | string[]
    sortTitle: {name: string, direction: boolean}
    filterTitle: CheckboxValueType[]
    countProd: boolean
    visible: boolean
    sortTitleFunc: (title: string) => void
    filteredTableContent: any,
    sortTableContent: any
    plainOptions: string[]
    tableRow:   JSX.Element[]
}

export const TableComponent = ({   titleTable,
                                   sortTitle,
                                   sortTitleFunc,
                                   filteredTableContent,
                                   tableRow,
                                   ...props
                               }: TableComponentPropsType) => {

    const onClickHandler = (title:string) => {
        sortTitleFunc(title)
    }

    return (
        <div style={{marginTop: '10px', backgroundColor: '#202020', position: 'relative'}}>
            <div className={cn(styles.tableTitle, props.name === 'marketplaces' && styles.mps)}>
                {Object.keys(titleTable).map((el, index) => <div key={index} className={styles.tableTop} onClick={() => onClickHandler(el)}>
                    {titleTable[el]} <span style={{marginLeft: 5}}>{sortTitle.name === el && (sortTitle.direction ? <UpOutlined /> : <DownOutlined />)}</span>
                </div>)}
            </div>
            {filteredTableContent.length ? tableRow : <EmptyComponent/>}
        </div>
    );
};

