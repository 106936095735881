import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {InfoPoint, PointListType, RequestPointType} from "../common/types";
import {AxiosError} from "axios";
import {pointApi} from "../api/pointApi";
import dayjs from "dayjs";


type InitialStateType = {
    list: PointListType[]
    shortInfoPoint: InfoPoint | null
    infoPoint: InfoPoint | null
    pointStatus: 'idle' | 'loading' | 'success' | 'error'
    startDate: string
    endDate: string
    point: string
    interval: 0 | 1 | 2
}

export const slice = createSlice({
    name: 'point',
    initialState: {
        list: [],
        shortInfoPoint: null,
        infoPoint: null,
        pointStatus: 'idle',
        startDate: dayjs( dayjs().valueOf() - 604800000).format('YYYY-MM-DD'), // - 1 week
        endDate: dayjs().format('YYYY-MM-DD'),
        point: '',
        interval: 0
    } as InitialStateType,
    reducers: {
        statusPointAC(state, action) {
            state.pointStatus = action.payload
        },
        setDateAC(state, action) {
            state.startDate = action.payload[0]
            state.endDate = action.payload[1]
        },
        setPointAC(state, action) {
            state.point = action.payload
        },
        setIntervalAC(state, action) {
            state.interval = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchShortInfoTC.fulfilled, (state, action) => {
                state.shortInfoPoint = action.payload
            })
            .addCase(fetchInfoTC.fulfilled, (state, action) => {
                state.infoPoint = action.payload
            })
            .addCase(fetchPointsListTC.fulfilled, (state, action) => {
                state.list = action.payload
            })

    }

})

export const fetchShortInfoTC = createAsyncThunk('point/fetchShortInfo', async (id: string,  {dispatch, rejectWithValue}) => {
    dispatch(statusPointAC('loading'))
    try {
        const res =  await pointApi.getPointId(id)
        dispatch(statusPointAC('success'))
        return res.data
    } catch(err) {
        const error = err as AxiosError
        if (!error.response) {
            throw err
        }
        dispatch(statusPointAC('error'))
        return rejectWithValue(error.response)
    }
})

export const fetchInfoTC = createAsyncThunk('point/fetchInfo', async (params: {id: string, data: RequestPointType },  {dispatch, rejectWithValue}) => {
    dispatch(statusPointAC('loading'))
    try {
        const res =  await pointApi.getDetailsPointId(params.id, params.data)
        dispatch(statusPointAC('success'))
        return res.data
    } catch(err) {
        const error = err as AxiosError
        if (!error.response) {
            throw err
        }
        dispatch(statusPointAC('error'))
        return rejectWithValue(error.response)
    }
})

export const fetchPointsListTC = createAsyncThunk('point/fetchPointsList', async (_,  {dispatch, rejectWithValue}) => {
    try {
        const res =  await pointApi.getPoints()
        return res.data
    } catch(err) {
        const error = err as AxiosError
        if (!error.response) {
            throw err
        }
        return rejectWithValue(error.response)
    }
})

export const pointReducer = slice.reducer
export const {
    statusPointAC,
    setDateAC,
    setPointAC,
    setIntervalAC
} = slice.actions