import React from 'react';
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";

import styles from './spinner.module.sass'

export const Spinner = () => {

    const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

    return (
        <div className={styles.main}>
            <Spin indicator={antIcon} />
        </div>
    );
};

