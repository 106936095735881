import axios from "axios";
import {HOST} from "../common/constants";

const instance = axios.create({
    baseURL: HOST,
    //baseURL: 'http://79.174.82.170:8007/api/v1/',
})

instance.interceptors.request.use(
    request => {

        request.headers.Authorization = `JWT ${localStorage.getItem('jwt')}`
        request.headers['Content-Type'] = 'multipart/form-data'
       /* request.headers['Cache-Control'] = 'no-cache'
        request.headers['Pragma'] = 'no-cache'
        request.headers['Expires'] = '0'*/

        return request
    },
    error => {
        console.log(error)
    }
);

export const bannerApi = {
    getData(params: any){
        return instance.post(`banner/`, params)
    },
    postData(params: any){
        return instance.post(`update-banner/`, params)
    },
}