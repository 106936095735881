import React from 'react';
import ReactApexChart from "react-apexcharts";
import {FONT_SIZE_DATA_LABELS} from "../../../../../common/constants";

type PriceColumnChartPropsType = {
    data: any,
    time: string[],
    title: string,
    categoriesXaxis?: any[]
    chart: string,
    typeChart: string
    spmCity?: string
}

export const PriceColumnChart = ({
                                     data,
                                     time,
                                     title,
                                     categoriesXaxis,
                                     chart,
                                     typeChart,
                                     spmCity
                                 }: PriceColumnChartPropsType) => {
    let count = 0
    data.map((el: any, index: number) => {
        el.data.forEach((item: any) => count += 1)
    })

    const options = {
        chart: {
            type: 'bar' as 'bar',
            toolbar: {
                show: false,
                tools: {
                    download: false
                }
            }
        },
        yaxis: {
            show: false,
            min: function (min: number) {
                return min
            },
            max: function (max: number) {
                return max
            },
        },
        //colors: settingsColors('', data),
        grid: {
            borderColor: '#202020',
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
            padding: {
                left: 40,
                right: 40,
                bottom: 20
            }
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'middle' as 'middle',
            offsetY: -5,
            formatter: function (val: any, opts: any) {
                return chart === 'Глубина скидки' || chart === 'Доля акций' ? val + '%' : val
            },
            style: {
                fontSize: FONT_SIZE_DATA_LABELS,
                fontWeight: 600,
            },
            background: {
                enabled: false,
            }
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['#202020']

        },
        plotOptions: {
            bar: {
                columnWidth: '70%',
                endingShape: 'rounded',
                hideZeroBarsWhenGrouped: true,
                dataLabels: {
                    position: 'center',
                    orientation: count >= 20 ? 'vertical' as 'vertical' : 'horizontal' as 'horizontal',
                    hideOverflowingLabels: true,
                    total: {
                        enabled: true,
                        style: {
                            color: '#373d3f',
                            fontSize: '15px',
                            fontFamily: undefined,
                            fontWeight: 600
                        }
                    }
                },

            }
        },
        title: {
            text: title,
            align: 'center' as const,
            margin: 20,
            style: {

                fontSize: '16px',
                fontWeight: 'bold' as const,
                color: '#FFFFFF'
            },
        },
        subtitle: {
            text: spmCity,
            align: 'center' as const,
            floating: false,
            style: {
                fontSize:  '12px',
                fontWeight:  'normal',
                color: '#FFFFFF'
            },
        },
        xaxis: {
            type: 'category' as 'category',
            categories: categoriesXaxis,
            labels: {
                show: true,
                rotate: -45,
                rotateAlways: categoriesXaxis&&categoriesXaxis.length > 8,
                style: {
                    colors: 'white',
                    fontWeight: 14,
                    fontSize: '12',
                },
            },
        },
        legend: {
            position: 'top' as 'top',
            offsetY: -10,
            horizontalAlign: 'left' as const,
            showForZeroSeries: false,
            fontSize: '14px',
            labels: {
                colors: '#FFFFFF'
            },
            onItemClick: {
                toggleDataSeries: true
            },
            onItemHover: {
                highlightDataSeries: false
            },
        },
        tooltip: {
            enabled: true,
            theme: 'dark',
            x: {
                show: true,
            },
            marker: {
                show: true,
            }
        },
    }

    return (
        <div style={{ height: typeChart === 'city' ?'500px' : '400px'}}>
            <ReactApexChart
                options={options}
                series={data}
                type={'bar'}
                height={'100%'}
                style={{color: '#FFFFFF'}}
            />
        </div>
    );
};



