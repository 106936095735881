import {dataType, YaxisType} from "../types";
import {findColor} from "./addDifferentColorsForMarkets";
import {marketPlacesColors, superMarketsColors} from "../optionsForColors";

export const settingsYaxis = (category: string, chart: string, data: dataType[], dataForCatalog: dataType[], salesData: dataType[], SOV: boolean, sell: boolean, dataCatalogSell: dataType[]) => {
    let yaxis: YaxisType[] = []
    let numbMarket:number[] = []
    let numbSOV:number[] = []
    let numbOther:number[] = []
    let numbAKB:number[] = []
    data.map((el) => {
        el.data.forEach((item) => {
            if (el.name === 'Рынок') {
                numbMarket.push(item[1])
            }
            if (el.name === 'Рынок') {
                numbAKB.push(item[1])
            }
            if (el.name.includes('SOV')) {
                numbSOV.push(item[1])
            }
            if (el.name !== 'Рынок' && !el.name.includes('SOV')) {
                numbOther.push(item[1])
            }

        })
    })
    let maxSOV = Math.max(...numbSOV)+1
    let maxMarket = Math.max(...numbMarket)
    let maxOther = Math.max(...numbOther)
    let minOther = Math.min(...numbOther)
    let maxAKB = Math.max(...numbOther)

    let numbSell:number[] = []
    dataForCatalog.map((el) => el.type === undefined && el.data.forEach((item: number[]) => numbSell.push(item[1])))
    let maxSell = Math.max(...numbSell)
    let numbCat:number[] = []
    dataForCatalog.map((el) => el.type === 'line' && el.data.forEach((item: number[]) => numbCat.push(item[1])))
    let maxCat = Math.max(...numbCat)

    let numbCatalogSell:number[] = []
    dataCatalogSell.map((el) => el.type === undefined && el.data.forEach((item: number[]) => numbCatalogSell.push(item[1])))
    let maxCatalogSell = Math.max(...numbCatalogSell)
    let numbCatCatalogSell:number[] = []
    dataCatalogSell.map((el) => el.type === 'line' && el.data.forEach((item: number[]) => numbCatCatalogSell.push(item[1])))
    let maxCatCatalogSell = Math.max(...numbCat)

    let numbSell1:number[] = []
    salesData.map((el) => el.type === undefined && el.data.forEach((item: number[]) => numbSell1.push(item[1])))
    let maxSell1 = Math.max(...numbSell1)
    let numbCat1:number[] = []
    salesData.map((el) => el.type === 'line' && el.data.forEach((item: number[]) => numbCat1.push(item[1])))
    let maxCat1 = Math.max(...numbCat1)

    if (chart === 'Рынок, темпы') {
        data.forEach((el: dataType) => {
                if (el.name === 'Рынок') {
                    yaxis.push({seriesName: el.name, show: false, min: maxMarket-600, max: maxMarket + 5})
                }
                if (el.name !== 'Рынок' && !el.name.includes('SOV')) {
                    yaxis.push({seriesName: el.name, show: false, min: SOV?(minOther>=0?-maxOther/2:-maxOther/2 + minOther*1.5):(minOther>=0?0:minOther), max: maxOther*1.2/*1.6*/})
                }
                if (el.name.includes('SOV')) {
                    yaxis.push({seriesName: el.name, show: false, min: 0, max: maxSOV*5})
                }
            }
        )
    }
    if (chart === 'Рынок, руб' && category === 'marketplaces') {
        data.forEach((el: dataType) => {
                if (el.name === 'Рынок') {
                    yaxis.push({seriesName: el.name, show: false, min: maxMarket-600, max: maxMarket})
                }
                if (el.name !== 'Рынок' && !el.name.includes('SOV')) {
                    yaxis.push({seriesName: el.name, show: false, min: SOV?(minOther>=0?-maxOther/2:-maxOther/2 + minOther*1.5):(minOther>=0?0:minOther), max: maxOther*1.2/*1.6*/})
                }
                if (el.name.includes('SOV')) {
                    yaxis.push({seriesName: el.name, show: false, min: 0, max: maxSOV*5})
                }
            }
        )
    }
    if (chart === 'Рынок, руб' && category === 'supermarkets') {
        data.forEach((el: dataType) => {
                if (el.name === 'Рынок') {
                    yaxis.push({seriesName: el.name, show: false, min: maxMarket-200, max: maxMarket})
                }
                if (el.name !== 'Рынок' && !el.name.includes('SOV')) {
                    yaxis.push({seriesName: el.name, show: false, min: SOV?(minOther>=0?-maxOther/2:-maxOther/2 + minOther*1.5):(minOther>=0?0:minOther), max: maxOther*1.2/*1.6*/})
                }
                if (el.name.includes('SOV')) {
                    yaxis.push({seriesName: el.name, show: false, min: 0, max: maxSOV*5})
                }
            }
        )
    }
    if (chart === 'АКБ') {
        yaxis.push({seriesName: 'АКБ', show: false, min: 0, max: maxAKB})
    }
    if (chart === 'Каталог' && !sell) {
        dataForCatalog.forEach((el) => {

            if (el.type === undefined) {
                yaxis.push({seriesName: 'sell', show: false, min: 0, max: maxSell*5})
            }
            if (el.type === 'line') {
                yaxis.push({seriesName: el.name, show: false, min: sell?-maxCat/3:0, max: maxCat})
            }
        })
    }
    if (chart === 'Каталог' && sell) {
        dataCatalogSell.forEach((el) => {

            if (el.type === undefined) {
                yaxis.push({seriesName: 'sell', show: false, min: 0, max: maxCatalogSell*5})
            }
            if (el.type === 'line') {
                yaxis.push({seriesName: el.name, show: false, min: sell?-maxCatCatalogSell/3:0, max: maxCatCatalogSell})
            }
        })
    }
    if (chart === 'Каталог1') {
        salesData.forEach((el) => {

            if (el.type === undefined) {
                yaxis.push({seriesName: 'sell', show: false, min: 0, max: maxSell1*5})
            }
            if (el.type === 'line') {
                yaxis.push({seriesName: el.name, show: false, min: sell?-maxCat1/3:0, max: maxCat1})
            }
        })
    }
    return yaxis
}

export const settingsStrokeDashArray = (chart: string, data: dataType[], seriesDataCatalog: dataType[], salesData: dataType[], dataCatalogSell: dataType[], sell: boolean) => {
    let dashArray: number[] = []
    if (chart === 'Рынок, темпы' || chart === 'Рынок, руб') {
        data.forEach((el: any) => el.name.indexOf('SOV') !== -1 ? dashArray.push(8) : dashArray.push(0))
    }
    if (chart === 'Каталог' && !sell) {
        seriesDataCatalog.forEach((el: any) => el.type === undefined ? dashArray.push(8) : dashArray.push(0))
    }
    if (chart === 'Каталог' && sell) {
        dataCatalogSell.forEach((el: any) => el.type === undefined ? dashArray.push(8) : dashArray.push(0))
    }
    if (chart === 'Каталог1') {
        salesData.forEach((el: any) => el.type === undefined ? dashArray.push(8) : dashArray.push(0))
    }
    return dashArray
}

export const settingsStrokeWidth = (chart: string, category: string, data: dataType[], seriesDataCatalog: dataType[], salesData: dataType[], dataCatalogSell: dataType[], sell: boolean) => {
    let width: number[] = []
    if (chart === 'Рынок, темпы' || chart === 'Рынок, руб') {
       data.forEach((el: any) => el.name.indexOf('SOV') !== -1 ? width.push(1) : width.push(5))
    }
    if (chart === 'Каталог'&& !sell) {
        seriesDataCatalog.forEach((el: any) => el.type === undefined ? width.push(1) : width.push(5))
    }
    if (chart === 'Каталог'&& sell) {
        dataCatalogSell.forEach((el: any) => el.type === undefined ? width.push(1) : width.push(5))
    }
    if (chart === 'Каталог1') {
        salesData.forEach((el: any) => el.type === undefined ? width.push(1) : width.push(5))
    }
    if (chart === 'АКБ %') {
        data.forEach((el: dataType) => width.push(0))
    }
    if (chart === 'Рост рынка' && category === 'marketplaces') {
        width = [5,5,0]
    }
    if (chart === 'Рост рынка' && category === 'supermarkets') {
        width = [5,0]
    } else {
        data.forEach((el: dataType) => width.push(5))
    }
    return width

}

export const settingsColors = (category: string, data: dataType[] ) => {
    let colors: string[] = []
    category === 'marketplaces' ? findColor(marketPlacesColors, data, colors) : findColor(superMarketsColors, data, colors)
    return colors
}

export const settingsPeriod = (data: dataType[]) => {
    let minDateArr: number[] = []
    data.map((el: dataType) => el.data.map((item: number[]) => minDateArr.push(item[0])))
    const minDate = Math.min.apply(null, minDateArr) - 150800000
    const maxDate = Math.max.apply(null, minDateArr) + 150800000
    return [minDate, maxDate]
}

export const settingsToolBar = () => {
    return window.innerWidth >= 920;
}

export const settingsColumnWidth = (chart: string) => {
    return chart === 'Рост рынка' ? '50%' : '80%'
}

export const settingsPlotOptionsOrientation = (chart: string) => {
    return chart === 'АКБ %' ? 'vertical' : 'horizontal'
}

export const settingsFill = (chart: string, data: dataType[]) => {
    let gradientToColors: string[] = []
    chart === 'Рост рынка' && data.map((el: dataType) => el.name === 'Рынок млрд' ? gradientToColors.push('#24b23e') : gradientToColors.push(''))
    return gradientToColors
}

export const dataForGrowingMarket = (data: dataType[]) => {
    let dataChange = [...data]
    let valuesMarket: number[] = []
    let range: number[] = []
    dataChange.map((el: dataType) => el.name === 'Рынок млрд' ? el.data.forEach((item: number[]) => valuesMarket.push(item[1])) : el)
    valuesMarket.map((el: number, index: number) => range.push(+(valuesMarket[index + 1] - el).toFixed(1)))
    range.pop()
    return range
}

export const offset = (chart: string) => {
    return chart === 'АКБ %' ? 4 : 0
}

export const colorTextDataLabels = (colors: string[]) => {
    let textColors: string[] = []
    colors.forEach((el) =>  textColors.push(el === '#ffe700' ? '#202020' : '#F5F5F5'))
    return textColors
}


export const minValueData = (data: [number, any][]) => {
    const arrVal = data.map((val) => val[1])
    return Math.min(...arrVal)
}
export const maxValueData = (data: [number, any][]) => {
    const arrVal = data.map((val) => val[1])
    return Math.max(...arrVal)
}

export const offsetXLabels = (period: number) => {
    let res = 0
    let day = +(period/86400000).toFixed()
    let dayPx = 1040/day
    if (period > 4100000000 && period < 4300000000) {
        return +(dayPx/6).toFixed() - .5
    }
    if (period > 5500000000) {
        return +dayPx.toFixed() + 2.5
    }
    return +(dayPx/6).toFixed()
}