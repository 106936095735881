import React, {useState} from 'react';
import {Checkbox, CheckboxProps} from 'antd';
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {citiesToSpm} from "../../../../common/optionsForCheckBoxes";
import styles from './checkboxesGroupPrice.module.sass'

type PropsType = {
    list: string[]
    defaultValue: CheckboxValueType[]
    callBack: (checkedValues: CheckboxValueType[]) => void
    value: CheckboxValueType[]
    spm?: string
    type: 'city' | 'spm'
}

export const CheckboxesGroupPrice = ({list, defaultValue, callBack, value, spm, type}: PropsType) => {


    const onChange = (checkedValues: CheckboxValueType[]) => {
        checkedValues.length && callBack(checkedValues)
    };


    let options: string[] = []
    citiesToSpm.forEach((el) => el.spm === spm ? options = el.cities : el)

    return (
        <div>
            <Checkbox.Group onChange={onChange}
                options={type === 'city' ? options : list}
                value={value}
                defaultValue={defaultValue}
                style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', }}

            />
        </div>
    );
};

