import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {authAPI} from "../api/authApi";
import {AuthStatusType, AuthType} from "../common/types";
import {AxiosError} from "axios";

export const LogIn = createAsyncThunk('auth/logIn', async (data:AuthType, {dispatch, rejectWithValue}) => {

    try {
        const res = await authAPI.auth(data)

        localStorage.setItem('jwt', res.data.access)
        localStorage.setItem('refresh', res.data.refresh)
        //localStorage.setItem('id', res.data.user.id)

        dispatch(isLoggedInAC(true))
        dispatch(setErrorAC(null))

        return res.data
    } catch (err) {
        const error = err as AxiosError

        if (!error.response) {
            throw err
        }
        dispatch(isLoggedInAC(false))
        dispatch(setErrorAC(error.response.status))
        return rejectWithValue(error.response)
    }
})


export const slice = createSlice({
    name: 'auth',
    initialState: {
        isLoggedIn: false,
        error: null,
        status: 'idle',
        loadingCharts: 'idle',
        loadingTables: 'idle',
        loadingCategories: 'idle',
        loadingCategoriesCarts: 'idle',
        loadingPrice: 'idle',
        loadingBanners: 'idle'
    } as AuthStatusType,
    reducers: {
        isLoggedInAC(state, action) {
            state.isLoggedIn = action.payload
        },
        setErrorAC(state,action) {
            state.error = action.payload
        },
        setAppStatusAC(state,action) {
            state.status = action.payload
        },
        loadingChartsAC(state, action) {
            state.loadingCharts = action.payload
        },
        loadingTablesAC(state, action) {
            state.loadingTables = action.payload
        },
        loadingCategoriesAC(state, action) {
            state.loadingCategories = action.payload
        },
        loadingCategoriesCartsAC(state, action) {
            state.loadingCategoriesCarts = action.payload
        },
        loadingPriceAC(state, action) {
            state.loadingPrice = action.payload
        },
        loadingBannersAC(state, action) {
            state.loadingBanners = action.payload
        },


    },
    extraReducers: (builder) => {
        builder
            .addCase(LogIn.rejected, (state, action:any) => {
                console.log(action.payload.data.detail)
                state.error = action.payload.status
                //state.error = action.payload.data.detail
            })
    }
})

export const authReducer = slice.reducer
export const {isLoggedInAC,
    setErrorAC,
    setAppStatusAC,
    loadingChartsAC,
    loadingTablesAC,
    loadingCategoriesAC,
    loadingCategoriesCartsAC,
    loadingPriceAC,
    loadingBannersAC
} = slice.actions