import dayjs from "dayjs";
import isoWeek from 'dayjs/plugin/isoWeek'

dayjs.extend(isoWeek)

export const formatTime = (date: number) => {
    return new Date(date).toISOString().split('T')[0]
}

export const formatArrayTime = (date: {min: number, max: number}) => {
    let arr = []
    arr.push(new Date(date.min).toISOString().split('T')[0], new Date(date.max).toISOString().split('T')[0])
    return arr
}

export const sessionStartDate = sessionStorage.getItem('startDate')
export const sessionEndDate = sessionStorage.getItem('endDate')
const localCatStartDate = localStorage.getItem('categoryStartDate')
const localCatEndDate = localStorage.getItem('categoryEndDate')
export const localCatDate = [localCatStartDate, localCatEndDate]



export const today = dayjs().valueOf()
export const start = dayjs().day() ? dayjs(today - 3628800000).day(1).format('YYYY-MM-DD') : dayjs(today - 3628800000).day(-6).format('YYYY-MM-DD')  //main
export const end = dayjs().day() ? dayjs(today).day(7).format('YYYY-MM-DD') : dayjs(today + 86399000).format('YYYY-MM-DD')  //main

export const defaultTime = [sessionStartDate?sessionStartDate:start, sessionEndDate?sessionEndDate:formatTime(today)]

export const periodLineChart = (time: string[]) => {

    let min = 0
    let max = 0
    if (dayjs().day()) {
        min = dayjs(time[0]).day(1).valueOf() + 10800000
        max = dayjs(time[1]).day(7).valueOf()+86399000 + 10800000
    }
    if (!dayjs().day()) {
        min = dayjs(time[0]).day(1).valueOf() + 10800000
        max = dayjs(time[1]).valueOf()+86399000 + 10800000
    }
    return [min, max]
}

export const getPeriod = (time: string[]) => {
    return periodLineChart(time)[1] - periodLineChart(time)[0]
}


//_________________________date for categories_________________________


export const getDateForCategories = (firstDay:any) => {
    let arr: any = []
    arr.push(formatTime(firstDay.valueOf() + 10800000),formatTime(firstDay.add(167, "hours")/*.subtract(1, "day")*/.valueOf()))
    return arr
}
export const defaultTimeForCategories = localCatDate[0]?localCatDate:getDateForCategories(dayjs().day(1))

export const valueOf = (time: any) => {
    return [dayjs(time[0]).valueOf() + 101200000,dayjs(time[1]).valueOf() + 101200000]
}

export const formatDateForCategories = (date: number) => {
    const startWeek = dayjs(date).day(1)
    const endWeek = dayjs(date).day(7)
    const weekNumber = dayjs(date).isoWeek()
    const result = dayjs(startWeek).format('DD.MM') + '-' + dayjs(endWeek).format('DD.MM') + '('+weekNumber+')'
    return result

}

export const firstDayOfTheWeek = (date: string) => {
    return dayjs(date).day(1).hour(1).valueOf()
}

export const getTimeCatToTime = (timeCat: string[]) => {                                           // переход от даты для категорий на обычную
    const start = dayjs(dayjs(timeCat[0]).valueOf() - 3628800000).format('YYYY-MM-DD')      // добавляет 6 недель назад
    const end = timeCat[1]
    return [start, end]
}

//________________________________________________________price__________________________________________________________________

export const endPriceTime = formatTime(today)

export const startPriceLineChart = dayjs(today - 3628800000).day(1).format('YYYY-MM-DD')
export const sessionStartPriceLineChart = sessionStorage.getItem('startLineChartTime')
export const sessionEndPriceLineChart = sessionStorage.getItem('endLineChartTime')


//export const startPriceColumnForAllChart = dayjs(today - 1209600000).day(1).format('YYYY-MM-DD')
export const startPriceColumnForAllChart = dayjs(today - 3024000000).day(1).format('YYYY-MM-DD')
export const sessionStartPriceColumnForAllChart = sessionStorage.getItem('startAllChartTime')
export const sessionEndPriceColumnForAllChart = sessionStorage.getItem('endAllChartTime')


//export const startPriceColumnCityChart = dayjs(today - 1209600000).day(1).format('YYYY-MM-DD')
export const startPriceColumnCityChart = dayjs(today - 3024000000).day(1).format('YYYY-MM-DD')
export const sessionStartPriceColumnCityChart = sessionStorage.getItem('startCityChartTime')
export const sessionEndPriceColumnCityChart = sessionStorage.getItem('endCityChartTime')

export const defaultLineChartTime = [
    sessionStartPriceLineChart?sessionStartPriceLineChart:startPriceLineChart,
    sessionEndPriceLineChart?sessionEndPriceLineChart:endPriceTime
]

export const defaultAllChartTime = [
    sessionStartPriceColumnForAllChart?sessionStartPriceColumnForAllChart:startPriceColumnForAllChart,
    sessionEndPriceColumnForAllChart?sessionEndPriceColumnForAllChart:endPriceTime
]

export const defaultCityChartTime = [
    sessionStartPriceColumnCityChart?sessionStartPriceColumnCityChart:startPriceColumnCityChart,
    sessionEndPriceColumnCityChart?sessionEndPriceColumnCityChart:endPriceTime
]




