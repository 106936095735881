import React, {useEffect} from 'react';
import {Button, Checkbox, CheckboxProps} from "antd";
import {CheckBoxCategoriesList, CheckBoxCategoriesShortList} from "../../../common/optionsForCheckBoxes";
import styles from './categories.module.sass'
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {tableChartCategoriesAC} from "../../../redux/categoriesReducer";
import {categoriesCheckboxes, choiceOfPlatform, selectTableChartCategories} from "../../../common/selectors";
import {checkBoxesCategoriesAC} from "../../../redux/optionsReducer";

export const Categories = () => {

    const dispatch = useAppDispatch()
    const tableCharts = useAppSelector(selectTableChartCategories)
    const platform = useAppSelector(choiceOfPlatform)
    //const visible = useAppSelector(categoriesCheckboxes)

    useEffect(() => {
        dispatch(checkBoxesCategoriesAC(true))
    }, [dispatch]);

    const onChange: CheckboxProps['onChange'] = async (e) => {
        const checkedValues = await e.target.value
        dispatch(tableChartCategoriesAC(checkedValues))
    };


    const getCheckBoxes = (list: {value: string, label: string}[]) => {
        return list.map((el) => <Checkbox key={el.value} checked={el.value === tableCharts} onChange={onChange}  value={el.value}  className={styles.item}>
            <div className={styles.label}>
                {el.label}
            </div>
        </Checkbox>)
    }

    const checkBoxes =  getCheckBoxes(platform==='stocks' ? CheckBoxCategoriesShortList : CheckBoxCategoriesList)

    const onClickHandler = () => {
        //dispatch(checkBoxesCategoriesAC(!visible))
        dispatch(tableChartCategoriesAC(''))
    }

    return (
        <>
            <div className={styles.categories}>
                <Button type={'link'} style={{marginBottom: '10px', marginRight: '40px'}} onClick={onClickHandler}>Все категории</Button>
                {checkBoxes}
            </div>
        </>

    );
};

