import React, {memo} from 'react';
import {title, titlePrice} from "../../../../common/optionsForTitle";
import {useAppSelector} from "../../../../redux/store";
import {choiceOfPlatform, selectCategory, selectChart, selectSwitchCategories} from "../../../../common/selectors";


export const ChartTitle = memo(() => {

    const chart = useAppSelector(selectChart)
    const category = useAppSelector(selectCategory)
    const categories = useAppSelector(selectSwitchCategories)
    const platform = useAppSelector(choiceOfPlatform)

    const subTitle = category === 'marketplaces' ? 'E-commerce. ' : (category === 'supermarkets'?'E-grocery. ':'')

    return (
        <div style={{color: "white", fontSize: '34px', fontWeight: 600}}>
            {platform==='stocks'&&title.map((el) => chart === el.name && subTitle + el.title)}
            {chart === 'Категории'&&'Количество товаров, доли категорий'}
            {chart !== 'Категории' && categories &&'Сравнение данных'}
            {platform === 'price' && titlePrice.map((el) => chart === el.name && subTitle + el.title)}
        </div>
    );
});


