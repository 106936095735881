export const plainOptionsMps = [
    'Wildberries',
    'Ozon',
    'Яндекс.Маркет',
    'AliExpress',
    'Мегамаркет',
];
export const plainOptionsSpm = [
    'Самокат',
    'Яндекс.Лавка',
    'ВкусВилл',
    'Купер',
    'Перекресток.Доставка',
    'Перекресток.Впрок',
    'Пятёрочка',
    'Магнит',
    'Яндекс.Еда',
    'Пятерочка.Доставка',
    'Яндекс.Маркет15',
    'Магнит.Доставка'
];

