import React, {lazy, Suspense} from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {AUTH, CHARTS, MAIN, MAP, NEWS} from "../../common/constants";
import {Auth} from "../../pages/auth";
import {Main} from "../../pages/main";
import {ChartsPage} from "../../pages/chartsPage";
import {MapInfoPointPage} from "../../pages/mapPage/mapInfoPointPage";
import {News} from "../../pages/news";


const MapPage= lazy(() => import("../../pages/mapPage")
    .then(({ MapPage }) => ({ default: MapPage })),
)


export const RoutesComponent = () => {

    return (
        <div>
            <Routes>
                <Route path={AUTH} element={<Auth/>}/>
                <Route path={MAIN} element={<Main/>}>
                    <Route path={MAIN} element={<Navigate to={CHARTS}/>}/>
                    <Route path={CHARTS} element={<ChartsPage/>}/>
                    {/*<Route path={NEWS} element={<News/>}/>
                    <Route path={MAP} element={<Suspense fallback={<div>loading</div>}><MapPage/></Suspense>}/>
                    <Route path={`${MAP}/:id`} element={<MapInfoPointPage/>}/>*/}
                </Route>
            </Routes>
        </div>
    );
};

