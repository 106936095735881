import React, {useEffect} from 'react';

import {ChartComponent} from "./chartComponent";
import {ChartTitle} from "./chartTitle";
import styles from './charts.module.sass'
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {
    choiceOfPlatform,
    dataForCharts,
    loadingCategories,
    loadingCharts,
    selectCategories,
    selectCategory,
    selectChart,
    selectDataApi,
    selectDataForCatalog,
    selectDataSale,
    selectMarketPlaces,
    selectMps,
    selectSelloffChecked,
    selectSwitchCategories,
    selectTableChartCategories,
    selectTime,
    selectTimeForCategories,
    visiblePDF
} from "../../../common/selectors";
import {CategoriesCharts} from "./categoriesCharts";
import {TableCategories} from "../tableCategories";
import {fetchDataCategories, fetchDataCategoriesForCharts, fetchDataForCatalog} from "../../../redux/categoriesReducer";
import {CategoriesColumnChart} from "./categoriesColumnChart";
import {Spinner} from "../../../common/spin";
import {EmptyComponent} from "../../../common/empty";
import {PriceCharts} from "./priceCharts";
import {requestForTableCharts} from "../../../common/requestForTableCharts";
import {fetchDataSale} from "../../../redux/saleReducer";
import dayjs from "dayjs";

export const Charts = () => {

    const dispatch = useAppDispatch()

    const categories = useAppSelector(selectSwitchCategories)
    const platform = useAppSelector(choiceOfPlatform)
    const chart = useAppSelector(selectChart)
    const category = useAppSelector(selectCategory)
    const dataCharts = useAppSelector(dataForCharts)
    const data = useAppSelector(selectDataApi)
    const dataForCatalog = useAppSelector(selectDataForCatalog)
    const sell = useAppSelector(selectSelloffChecked)
    const timeCat = useAppSelector(selectTimeForCategories)
    const mps = useAppSelector(selectMps)
    const loadingForCharts = useAppSelector(loadingCharts)
    const loadingForCategories = useAppSelector(loadingCategories)
    const catData = useAppSelector(selectCategories)
    const visible = useAppSelector(visiblePDF)
    const time = useAppSelector(selectTime)
    const marketPlacesNames = useAppSelector(selectMarketPlaces)
    const tableCharts = useAppSelector(selectTableChartCategories)
    const salesData = useAppSelector(selectDataSale)

    useEffect(() => {
        if (categories && chart === 'Категории') {
            dispatch(fetchDataCategories({from_date: timeCat[0], to_date: timeCat[1], spm: mps}))
        }
        if (categories && chart !== 'Категории') {
            dispatch(fetchDataCategories({from_date: timeCat[0], to_date: timeCat[1]}))
        }
        if (!categories && chart === 'Каталог') {
            dispatch(fetchDataForCatalog({from_date: time[0], to_date: dayjs(dayjs(time[1]).valueOf() + 86400000).format('YYYY-MM-DD')}))
            dispatch(fetchDataSale({from_date: time[0], to_date: dayjs(dayjs(time[1]).valueOf() + 86400000).format('YYYY-MM-DD'), gl_category: tableCharts ? tableCharts : 'Общая'}))
            dispatch(fetchDataCategoriesForCharts(requestForTableCharts(time, tableCharts)))
        }
        if (tableCharts) {
            dispatch(fetchDataCategoriesForCharts(requestForTableCharts(time, tableCharts))) // конечная дата + конец недели
        }
        if (!categories && chart === 'Каталог1') {
            dispatch(fetchDataSale({from_date: time[0], to_date: time[1], gl_category: tableCharts ? tableCharts : 'Общая'}))
        }

    }, [dispatch, mps, timeCat, categories, chart, time, marketPlacesNames, sell, tableCharts, platform])

    const charts = () => {
        if (loadingForCategories === 'loading' || loadingForCharts === 'loading') {
            return <Spinner/>
        }
        if (loadingForCategories === 'succeeded' || loadingForCharts === 'succeeded') {
            if (categories && catData.length && platform === 'categories') {
                if (chart === 'Категории' ) {
                    return <CategoriesCharts/>
                }
                if (chart === 'Данные 1 уровня' || chart === 'Сравнение общее') {
                    return <TableCategories/>
                }
                if (chart === 'Данные, товары' || chart === 'Данные, %') {
                    return <CategoriesColumnChart/>
                }
            }
            if (platform === 'price') {
                return <PriceCharts/>
            }
            if (!categories && platform === 'stocks' && (data.length || (dataForCatalog.length && chart === 'Каталог'  && category === 'marketplaces' /*&& !tableCharts*/))) {
                return<ChartComponent/>
            }
            if (!categories && platform === 'stocks' && (data.length || (salesData.length &&  chart === 'Каталог1' && category === 'marketplaces' ))) {
                return<ChartComponent/>
            }
            return <EmptyComponent/>

        }
    }

    const chartsContent = charts()

    return (
        <div className={styles.main}>
            <div className={styles.title}>
                <ChartTitle/>
                <div className={styles.divider} style={{display: visible ? 'block' : 'none'}}></div>
            </div>
            <div className={styles.chart} style={{height: platform=== 'price'?1330:700}}>
                {chartsContent}
            </div>

        </div>
    );
};



