import axios from "axios";
import {DataCategoriesRequestType} from "../common/types";
import {HOST} from "../common/constants";

const instance = axios.create({
    baseURL: HOST,
})
instance.interceptors.request.use(
    request => {

        request.headers.Authorization = `JWT ${localStorage.getItem('jwt')}`
        request.headers['Content-Type'] = 'multipart/form-data'

        return request
    },
    error => {
        console.log(error)
    }
);

export const dataPriceApi = {
    getDataPrice(params: DataCategoriesRequestType){
        return instance.get(`comparison-item/`, {params})
    }
}



