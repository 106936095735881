import React from "react";
import * as FileSaver from 'file-saver';
import * as XLSX from "xlsx";
import {Button} from "antd";
import {useAppSelector} from "../redux/store";
import {
    selectCategories,
    selectChart, selectDataTable, selectDataTotalTable,
    selectDataXLSL,
    selectSwitchCategories,
    selectTableContent
} from "../common/selectors";



export const ExportCSV = () => {

    const dataXLSL = useAppSelector(selectDataXLSL)
    const catData = useAppSelector(selectCategories)
    const chart = useAppSelector(selectChart)
    const categories = useAppSelector(selectSwitchCategories)
    const tableContent = useAppSelector(selectTableContent)
    const dataTable = useAppSelector(selectDataTable)
    const dataTotalTable = useAppSelector(selectDataTotalTable)


    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = () => {
        const ws = XLSX.utils.json_to_sheet(categories ? catData : dataXLSL);
        const ws2 = XLSX.utils.json_to_sheet(categories  ? (chart==='Сравнение общее'? dataTotalTable : dataTable) : tableContent);
        const wb = { Sheets: { charts: ws, table: ws2 }, SheetNames: ["charts", "table"] };

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, chart + fileExtension);
    };

    return (
        <Button  onClick={exportToCSV} style={{borderRadius: 5, backgroundColor: '#1E914E', color: 'white', marginLeft: 10}}>
            Экспорт XLSX
        </Button>
    );
};