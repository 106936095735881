import React from 'react';
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {
    loadingTables,
    selectCategory,
    selectChart, selectCheckboxes, selectFilterTitle, selectHidden, selectSortTitle,
    selectSwitchCategories,
    setTable,
    visiblePDF
} from "../../../common/selectors";
import {Button, Switch} from "antd";
import {hiddenChartAC, setTableAC} from "../../../redux/optionsReducer";
import styles from './settingsForTable.module.sass';
import {
    selectCountProdAC, selectFilterTitleAC,
    selectSortTitleAC
} from "../../../redux/tableReducer";
import Checkbox, {CheckboxChangeEvent} from "antd/es/checkbox";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {plainOptionsMps, plainOptionsSpm} from "../../../common/optionsTableShares";


type SettingForTablePropsType = {
    buttonName: string
}

export const SettingForTable = ({buttonName}: SettingForTablePropsType) => {

    const dispatch = useAppDispatch()

    const chart = useAppSelector(selectChart)
    const category = useAppSelector(selectCategory)
    const loadingForTables = useAppSelector(loadingTables)
    const categories = useAppSelector(selectSwitchCategories)
    const table = useAppSelector(setTable)
    const visible = useAppSelector(visiblePDF)
    const sortTitle = useAppSelector(selectSortTitle)
    const filterTitle = useAppSelector(selectFilterTitle)
    const checkboxes = useAppSelector(selectCheckboxes)
    const hidden = useAppSelector(selectHidden)

    const onChange = (checked: boolean) => {
        dispatch(setTableAC(checked))
    };

    const onChangeChart = (checked: boolean) => {
        dispatch(hiddenChartAC(checked))
    };

    const onChangeTab = (checkedValues: CheckboxValueType[]) => {
        dispatch(selectFilterTitleAC(checkedValues))

    };

    const onChangeHandler = (e: CheckboxChangeEvent) => {
        dispatch(selectCountProdAC(e.target.checked))
    }

    return (
        <div style={{display: 'flex'}}>
            <div className={styles.switch}>
                {!categories&&window.innerWidth > 1000&&<Switch checkedChildren="таблица" unCheckedChildren="график" defaultChecked={table} onChange={onChange} checked={table} style={{opacity: visible?1:0}}/>}
                {!categories&&window.innerWidth > 1000&&<Switch checkedChildren="открепить" unCheckedChildren="закрепить" defaultChecked={table} onChange={onChangeChart} checked={hidden} style={{opacity: visible?1:0}}/>}
            </div>
            <div className={styles.tableOption} style={{opacity: visible?1:0}}>
                <Button disabled={!table} type={'link'} onClick={() => dispatch(selectSortTitleAC({...sortTitle, name: 'spm', direction: true}))}>{buttonName}</Button>
                <div>
                    <Checkbox.Group options={category==='marketplaces'?plainOptionsMps:checkboxes} value={filterTitle} defaultValue={filterTitle}  onChange={onChangeTab} style={{marginTop: '10px'}}/>
                    {/*{category!=='supermarkets'&&<Checkbox onChange={onChangeHandler}>показать всё</Checkbox>}*/}
                </div>
            </div>
        </div>
    );
};

