import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ChartsType, DataRequestType, DataResType, dataType} from "../common/types";
import {AppRootStateType} from "./store";
import {dataApi} from "../api/dataApi";
import {AxiosError} from "axios";
import {loadingChartsAC, setErrorAC} from "./authReducer";
import dayjs from "dayjs";
import {OZ_AKB_FACTOR, SAM_AKB_FACTOR, WB_AKB_FACTOR, WB_AKB_FACTOR_MIN} from "../common/constants";
import {maxValueData, minValueData} from "../common/optionsForCharts/visualSettings";
import {changeDataFactorAC} from "./optionsReducer";
import {orderData} from "../common/orderData";

const test: DataResType[] = [
    {
        gin_name: 'Рынок, темпы',
        spm_name: 'WB Food',
        category: 'supermarkets',
        start_date: '2024-05-19',
        end_date: '2024-05-13',
        value_item: 10.4,
        gin: 3,
        mkp: 1
    },
    {
        gin_name: 'Рынок, темпы',
        spm_name: 'WB Food',
        category: 'supermarkets',
        start_date: '2024-05-20',
        end_date: '2024-05-26',
        value_item: 10.4,
        gin: 3,
        mkp: 1
    },


]

export const fetchData = createAsyncThunk<any, any, { state: AppRootStateType }>('fetchData', async (param: DataRequestType,  {dispatch, rejectWithValue, getState}) => {
    dispatch(loadingChartsAC('loading'))
    try {
        const {options} = getState()
        const res = await dataApi.getData(param) // результат запроса данных с сервера
        const data:DataResType[] = []
        orderData.forEach((el) => {
            res.data.forEach((item:DataResType) => {
                return item.spm_name === el ? data.push(item):item
            })
        })
        let dataName:string[] = []               // массив названий маркетов
        let dataForCharts:dataType[] = []     // массив для отрисовки графиков
        data.sort((a:DataResType,b:DataResType) => dayjs(a.start_date).valueOf() - dayjs(b.start_date).valueOf())
        data.map((el: DataResType) => !dataName.includes(el.spm_name) && dataName.push(el.spm_name))
        dataName.forEach((el) => {
            dataForCharts.push({
                name: el,
                type: '',
                data: []
            })
        })
        data.forEach((el: DataResType) => {
            let middleTime = ((new Date(el.end_date).valueOf() + 90000000 - new Date(el.start_date).valueOf())/2) + new Date(el.start_date).valueOf()
            dataForCharts.forEach((item) => (item.name === el.spm_name) ? item.data.push([middleTime, el.value_item]) : item)
            dataForCharts.forEach((item) => dataForCharts.forEach((item) => options.chart === 'АКБ %' || options.chart === 'Рост рынка' && item.name === 'Рынок млрд' ? item.type = 'column' : item.type = 'line'))

        })
        let filteredData: dataType[] = []     // фильтрованный массив по маркетам
        dataForCharts.forEach((el) => options.filter.includes(el.name) && filteredData.push(el))
        const changeData = filteredData.map((el:dataType) => {
                if (options.chart === 'АКБ' && el.name === 'Самокат') {
                    return {
                        ...el, data: el.data.map((item) => {
                            return [item[0], item[1] -  SAM_AKB_FACTOR]
                        })
                    }
                }
            if (options.chart === 'АКБ' && el.name === 'Wildberries') {
                const min = minValueData(el.data)
                const max = maxValueData(el.data)
                const delta = max - min
                return {
                    ...el, data: el.data.map((item) => {
                        if (delta >= 3500000 ) {
                            return [item[0], item[1] -  WB_AKB_FACTOR_MIN ]
                        }
                        if (delta < 3500000 ) {
                            dispatch(changeDataFactorAC(true))
                            return [item[0], item[1] -  WB_AKB_FACTOR_MIN]
                        }

                    })
                }
            }
            if (options.chart === 'АКБ' && el.name === 'Ozon' ) {
                const min = minValueData(el.data)
                const max = maxValueData(el.data)
                const delta = max - min
                return {
                    ...el, data: el.data.map((item) => {
                        if (delta >= 1000000) {
                            return [item[0], item[1]]
                        }
                        if (delta < 1000000) {
                            return [item[0], item[1]]
                        }

                    })
                }
            } else return el
        })
        dispatch(loadingChartsAC('succeeded'))
        return [changeData, res.data, dataName]
    } catch(err) {
        dispatch(loadingChartsAC('failed'))
        const error = err as AxiosError

        if (!error.response) {
            throw err
        }
        dispatch(setErrorAC(error.response.status))
        return rejectWithValue(error.response)
    }
})

export const slice = createSlice({
    name: 'charts',
    initialState: {
        dataAPI: [],
        dataXLSL: [],
        dataName: [],
        error: null
    } as ChartsType,
    reducers: {

        },
    extraReducers: (builder) => {
        builder
            .addCase(fetchData.fulfilled, (state, action) => {
                state.dataAPI = action.payload[0]
                state.dataXLSL = action.payload[1]
                state.dataName = action.payload[2]
            })
            .addCase(fetchData.rejected, (state, action:any) => {
                state.error = action.payload.status
            })
    }
})

export const chartsReducer = slice.reducer
