import React, {useState} from 'react';
import ReactApexChart from "react-apexcharts";
import {formatDateForCategories, getPeriod, periodLineChart, valueOf} from "../../../../../common/optionsForDate";
import dayjs from "dayjs";
import {weekAnnotations} from "../../../../../common/optionsForCharts/weekAnnotations";
import {annoPrice} from "../../../../../common/optionsForCharts/annotationsForPriceLineCharts";
import {offsetXLabels, settingsColors} from "../../../../../common/optionsForCharts/visualSettings";
import {formatGrade} from "../../../../../common/optionsForCategories/table";
import {CATALOG_FACTOR, SAM_AKB_FACTOR, WB_AKB_FACTOR_MIN} from "../../../../../common/constants";

type PriceLineChartPropsType = {
    data: any,
    time: string[],
    title: string,
}

export const PriceLineChart = ({
                                   data,
                                   time,
                                   title,}:PriceLineChartPropsType) => {


    const [clickAnno, setClickAnno] = useState<any[]>([])

    const period = getPeriod(time)
    const tickAmountTime = +(period/86400000).toFixed()
    const tickAmountWeek = +(period/604800000).toFixed()
    const offsetXLab = offsetXLabels(period)
    const formTime = valueOf(time)


    const options = {
        chart: {
            type: 'line' as 'line',
            events: {
                legendClick: function (chartContext: any, seriesIndex: number, config: any) {
                    return clickAnno.includes(seriesIndex) ? setClickAnno(clickAnno.filter((el: number) => el !== seriesIndex)) : setClickAnno([...clickAnno, seriesIndex])
                },
            },
            toolbar: {
                show: false,
                tools: {
                    download: false,
                    zoomin: false,
                    zoomout: false,
                }
            },
            zoom: {
                enabled: false
            }
        },
        yaxis: {
            show: false,
            min: function (min: number) {
                return min
            },
            max: function (max: number) {
                return max
            },
        },
        colors: settingsColors('', data),
        grid: {
            borderColor: '#202020',
            show: false,
            padding: {
                left: 0,
                right: 0,
                //bottom: 20
            }
        },
        title: {
            text: title,
            align: 'center' as 'center',
            margin: 20,
            style: {

                fontSize:  '16px',
                fontWeight:  'bold' as 'bold',
                color:  '#FFFFFF'
            },
        },
        markers: {
            size: 2
        },
        xaxis: {
            type: 'numeric' as const,
            min: periodLineChart(time)[0] ,
            max: periodLineChart(time)[1] ,
            tickAmount: data.length&&window.innerWidth>1000 ? (period > 5500000000 ? tickAmountWeek : tickAmountTime) : tickAmountWeek,
            tickPlacement: 'between',
            axisTicks: {
                show: period < 6200000000 && true,
                offsetY: 20,
                height: 10,
            },
            axisBorder: {
                show: true,
                color: '#F5F5F5',
                height: 3,
                width: '120%',
                //offsetX: -100,
                offsetY: -1
            },
            labels: {
                show: true,
                formatter: function (value: any, timestamp: any, opts: any) {
                    return window.innerWidth>1000 ? (period > 5500000000 ? formatDateForCategories(value) : dayjs(value).format('DD')) : formatDateForCategories(value)
                },
                offsetX: offsetXLab,
                offsetY: 3,
                style: {
                    colors: 'white',
                    fontWeight: 14,
                    fontSize: '12',
                },

            },
            tooltip: {
                enabled: false,
            },
        },
        annotations: {
            xaxis: weekAnnotations(formTime, period)[0],
            points: annoPrice(data, clickAnno)
        },
        legend: {
            position: 'top' as 'top',
            offsetY: -15,
           /* formatter: function(seriesName: any, opts: any) {
                let res = seriesName
                if (opts.w.globals.initialSeries[opts.seriesIndex].type === undefined) {
                    res = seriesName + ' (sale)'
                }
                return seriesName
            },*/
            horizontalAlign: 'left' as 'left',
            showForZeroSeries: false,
            fontSize: '14px',
            labels: {
                colors: '#FFFFFF'
            },
            onItemClick: {
                toggleDataSeries: true
            },
            onItemHover: {
                highlightDataSeries: false
            },
        },
        tooltip: {
            enabled: true,
            theme: 'dark',
            followCursor: false,
            style: {
                fontSize: '14px',

            },
            x: {
                show: true,
                formatter: function (value:any) {
                    return formatDateForCategories(value)
                }
            },
            marker: {
                show: true,
            },
            fixed: {
                enabled: true,
                position: 'topRight',
                offsetX: 0,
                offsetY: 0,
            },

        },
    }

    return (
        <div style={{height: '400px'}}>
            <ReactApexChart
                options={options}
                series={data}
                type={'line'}
                height={'100%'}
                style={{color: '#FFFFFF'}}
            />
        </div>
    );
};

