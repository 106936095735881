import axios from "axios";
import {HOST} from "../common/constants";

const instance = axios.create({
    baseURL: HOST,

})

export const authAPI = {
    auth(data: any) {
        return instance.post('token/',  data)
    }
}
