import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {NewsType} from "../common/types";
import {AxiosError} from "axios";
import {newsApi} from "../api/newsApi";
import dayjs from "dayjs";

const endDate = dayjs(dayjs().valueOf() - 1).day(7).format('YYYY-MM-DD')
const start = dayjs(dayjs().valueOf() - 2629743000).day(1).format('YYYY-MM-DD') // -месяц


export const slice = createSlice({
    name: 'news',
    initialState: {
        dateNews: [start, endDate],
        dataNews: []
    } as NewsType,
    reducers: {
        setNewsDateAC(state, action) {
            state.dateNews = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDataNewsTC.fulfilled, (state, action) => {
                state.dataNews = action.payload
            })

        }

})

export const fetchDataNewsTC = createAsyncThunk('fetchDataNews', async (param: {from_date: string, to_date: string},  {rejectWithValue, getState}) => {
    try {
        const res = await newsApi.getData(param.from_date, param.to_date)
        return res.data.results
    }catch(err) {
        const error = err as AxiosError

        if (!error.response) {
            throw err
        }
        return rejectWithValue(error.response)
    }
})

export const newsReducer = slice.reducer
export const {setNewsDateAC} = slice.actions