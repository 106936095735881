import React from 'react';

import styles from './empty.module.sass'
import {Empty} from "antd";
import {useAppSelector} from "../../redux/store";
import {choiceOfPlatform} from "../selectors";

export const EmptyComponent = () => {

    const platform = useAppSelector(choiceOfPlatform)

    const style = platform === 'price' ? styles.price : styles.main

    return (
        <div className={style}>
            <Empty description={'Нет данных, соответствующих выбранным параметрам'}/>
        </div>
    );
};

