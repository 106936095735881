import {changeDataPriceType, DataPriceResponseType} from "../common/types";


export const dataArray = (data:DataPriceResponseType[]) => {
    let weeks: string[] = []
    let spm: string[] = []
    let changeData: DataPriceResponseType[] = []
    data.forEach((el) => (
        !weeks.includes(el.week) ? weeks.push(el.week) : el,
        !spm.includes(el.seller_name) ? spm.push(el.seller_name) : el
    ))
    return [weeks, spm]
}


export const average = (data: DataPriceResponseType[], weeks: string[], spm: string[]) => {

    let weekSpm: changeDataPriceType[] = []

    spm.forEach((el) => {
        weeks.forEach((item) => {
            weekSpm.push({
                seller_name: el,
                week: item,
                from_date: '',
                to_date: '',
                discount_rate: 0,
                city_count_discount_rate: 0,
                pi_virt: 0,
                city_count_pi_virt: 0,
                pi_fact: 0,
                city_count_pi_fact: 0,
                intersection_rate: 0,
                city_count_intersection_rate: 0,
                promo_item: 0,
                city_count_promo_item: 0,
            })
        })
    })

    weekSpm.map((el) => {
        data.map((item) => el.seller_name === item.seller_name && el.week === item.week ?
            (el.intersection_rate += item.intersection_rate,
                item.intersection_rate ? el.city_count_intersection_rate += 1 : 0,
                el.discount_rate = el.discount_rate + item.discount_rate,
                item.discount_rate ? el.city_count_discount_rate += 1 : 0,
                el.pi_virt = el.pi_virt + item.pi_virt,
                item.pi_virt ? el.city_count_pi_virt += 1 : 0,
                el.pi_fact = el.pi_fact + item.pi_fact,
                item.pi_fact ? el.city_count_pi_fact += 1 : 0,
                el.promo_item = el.promo_item + item.promo_item,
                item.promo_item ? el.city_count_promo_item += 1 : 0,
                el.from_date = item.from_date,
                el.to_date = item.to_date)
            : el
        )
    })
    weekSpm.map((el) => {
        el.intersection_rate = el.city_count_intersection_rate ? el.intersection_rate/el.city_count_intersection_rate : el.intersection_rate
        el.pi_virt = el.city_count_pi_virt ? el.pi_virt/el.city_count_pi_virt : el.pi_virt
        el.pi_fact = el.city_count_pi_fact ? el.pi_fact/el.city_count_pi_fact : el.pi_fact
        el.discount_rate = el.city_count_discount_rate ? el.discount_rate/el.city_count_discount_rate : el.discount_rate
        el.promo_item = el.city_count_promo_item ? el.promo_item/el.city_count_promo_item : el.promo_item
    })
    return weekSpm
}