import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AppRootStateType} from "./store";
import {
    changeDataPriceType,
    DataColumnType,
    DataPriceRequestType,
    DataPriceResponseType,
    dataType
} from "../common/types";
import {loadingPriceAC, setErrorAC} from "./authReducer";
import {AxiosError} from "axios";
import {dataPriceApi} from "../api/dataPriceApi";
import {defaultAllChartTime, defaultCityChartTime, defaultLineChartTime,} from "../common/optionsForDate";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {average, dataArray} from "../utils/averageForPriceReducer";
import dayjs from "dayjs";
import {orderData} from "../common/orderData";
import {spmForPrice} from "../common/optionsForCheckBoxes";


type InitialStatePropsType = {
    priceLineChart: {
        data: any[],
        time: string[]
        spm: string[]
    },
    priceColumnForAllChart: {
        data: any[],
        orderSpm: string[]
        spm: CheckboxValueType[],
        time: string[]
    },
    priceColumnCityChart: {
        data: any[],
        orderCities: string[],
        cities: string[],
        spm: string,
        time: string[]
    },
    collapse: string[]
    error?: any
}


export const fetchDataPrice = createAsyncThunk<any, any, { state: AppRootStateType }>('fetchDataPrice', async (param: DataPriceRequestType, {
    dispatch,
    rejectWithValue,
    getState
}) => {
    dispatch(loadingPriceAC('loading'))
    try {
        const {options} = getState()
        const res = await dataPriceApi.getDataPrice(param)
        //res.data.map((el:DataPriceResponseType) => el.from_date = firstDayOfTheWeek(el.from_date) + 309600000)
        const data: DataPriceResponseType[] = []
        orderData.forEach((el) => {
            res.data.forEach((item:DataPriceResponseType) => {
                return item.seller_name === el ? data.push(item):item
            })
        })
        const weeks = dataArray(data)[0]
        const spm = dataArray(data)[1]
        const weekSpm = average(data, weeks, spm)

        //let dataName:string[] = []
        let dataForCharts:dataType[] = []
        //weekSpm.map((el: changeDataPriceType) => !dataName.includes(el.seller_name) && dataName.push(el.seller_name))
        spm.forEach((el) => {
            dataForCharts.push({
                name: el,
                type: 'line',
                data: []
            })
        })
        weekSpm.forEach((el: changeDataPriceType) => {
            //let middleTime = ((new Date(el.from_date).valueOf() + 90000000 - new Date(el.to_date).valueOf())/2) + new Date(el.from_date).valueOf()
            const middleTime = dayjs(el.from_date).valueOf() + 302400000
            if (options.chart === 'PI фактический') {
                dataForCharts.forEach((item) => (item.name === el.seller_name) ? item.data.push([middleTime, el.pi_fact?+el.pi_fact.toFixed(2):null]) : item)
            }
            if (options.chart === 'PI витринный') {
                dataForCharts.forEach((item) => (item.name === el.seller_name) ? item.data.push([middleTime, el.pi_virt?el.pi_virt.toFixed(2):null]) : item)
            }
            if (options.chart === 'Доля акций') {
                dataForCharts.forEach((item) => (item.name === el.seller_name) ? item.data.push([middleTime, el.promo_item?el.promo_item.toFixed(2):null]) : item)
            }
            if (options.chart === 'Глубина скидки') {
                dataForCharts.forEach((item) => (item.name === el.seller_name) ? item.data.push([middleTime, el.discount_rate?el.discount_rate.toFixed(2):null]) : item)
            }

        })

        dispatch(loadingPriceAC('succeeded'))

        return dataForCharts
    } catch (err) {
        dispatch(loadingPriceAC('failed'))
        const error = err as AxiosError
        if (!error.response) {
            throw err
        }
        dispatch(setErrorAC(error.response.status))
        return rejectWithValue(error.response)
    }
})

export const fetchDataAllPrice = createAsyncThunk<any, any, { state: AppRootStateType }>('fetchDataAllPrice', async (param: DataPriceRequestType, {
    dispatch,
    rejectWithValue,
    getState
}) => {
    dispatch(loadingPriceAC('loading'))
    try {
        const {options} = getState()
        const res = await dataPriceApi.getDataPrice(param)
        const data: DataPriceResponseType[] = [...res.data]
        const weeks = dataArray(data)[0]
        const spm = dataArray(data)[1]
        const weekSpm = average(data, weeks, spm)

        let dataForCharts:DataColumnType[] = []

        weeks.forEach((el) => {
            dataForCharts.push({
                name: el,
                type: 'column',
                data: []
            })
        })

        weekSpm.forEach((el: changeDataPriceType) => {
            if (options.chart === 'PI фактический') {
                dataForCharts.forEach((item) => (item.name === el.week) ? item.data.push(el.pi_fact?el.pi_fact.toFixed(2):null) : item)
            }
            if (options.chart === 'PI витринный') {
                dataForCharts.forEach((item) => (item.name === el.week) ? item.data.push(el.pi_virt?el.pi_virt.toFixed(2):null) : item)
            }
            if (options.chart === 'Доля акций') {
                dataForCharts.forEach((item) => (item.name === el.week) ? item.data.push(el.promo_item?(el.promo_item*100).toFixed(0):null) : item)
            }
            if (options.chart === 'Глубина скидки') {
                dataForCharts.forEach((item) => (item.name === el.week) ? item.data.push(el.discount_rate?(el.discount_rate*100).toFixed(0):null) : item)
            }
        })

        dispatch(loadingPriceAC('succeeded'))
        return [dataForCharts, spm]
    } catch (err) {
        dispatch(loadingPriceAC('failed'))
        const error = err as AxiosError
        if (!error.response) {
            throw err
        }
        dispatch(setErrorAC(error.response.status))
        return rejectWithValue(error.response)
    }
})

export const fetchDataCityPrice = createAsyncThunk<any, any, { state: AppRootStateType }>('fetchDataCityPrice', async (param: DataPriceRequestType, {
    dispatch,
    rejectWithValue,
    getState
}) => {
    dispatch(loadingPriceAC('loading'))
    try {
        const {options} = getState()
        const res = await dataPriceApi.getDataPrice(param)
        const data = [...res.data]
        const weeks = dataArray(data)[0]
        let dataForCharts:DataColumnType[] = []
        weeks.forEach((el) => {
            dataForCharts.push({
                name: el,
                type: 'column',
                data: []
            })
        })

        data.forEach((el: DataPriceResponseType) => {
            if (options.chart === 'PI фактический') {
                dataForCharts.forEach((item) => (item.name === el.week) ? item.data.push(el.pi_fact?el.pi_fact.toFixed(2):null) : item)
            }
            if (options.chart === 'PI витринный') {
                dataForCharts.forEach((item) => (item.name === el.week) ? item.data.push(el.pi_virt?el.pi_virt.toFixed(2):null) : item)
            }
            if (options.chart === 'Доля акций') {
                dataForCharts.forEach((item) => (item.name === el.week) ? item.data.push(el.promo_item?(el.promo_item*100).toFixed(0):null) : item)
            }
            if (options.chart === 'Глубина скидки') {
                dataForCharts.forEach((item) => (item.name === el.week) ? item.data.push(el.discount_rate?(el.discount_rate*100).toFixed(0):null) : item)
            }
        })

        dispatch(loadingPriceAC('succeeded'))

        return [dataForCharts, ]
    } catch (err) {
        dispatch(loadingPriceAC('failed'))
        const error = err as AxiosError
        if (!error.response) {
            throw err
        }
        dispatch(setErrorAC(error.response.status))
        return rejectWithValue(error.response)
    }
})

export const slice = createSlice({
    name: 'price',
    initialState: {
        priceLineChart: {
            data: [],
            time: defaultLineChartTime,
            spm: spmForPrice,
        },
        priceColumnForAllChart: {
            data: [],
            orderSpm: [],
            spm: ['Самокат'],
            time: defaultAllChartTime,
        },
        priceColumnCityChart: {
            data: [],
            orderCities: [],
            cities: ['Москва'],
            spm: 'Самокат',
            time: defaultCityChartTime,
        },
        collapse: ['1','2','3'],
        error: null
    } as InitialStatePropsType,
    reducers: {
        changePriceLineChartAC(state, action) {
            state.priceLineChart.time = action.payload
        },
        selectSpmPriceForLineChartAC(state, action) {
            state.priceLineChart.spm = action.payload
        },
        changePriceColumnForAllChartAC(state, action) {
            state.priceColumnForAllChart.time = action.payload
        },
        changeColumnCityChartAC(state, action) {
            state.priceColumnCityChart.time = action.payload
        },

        changeSpmPriceColumnForAllChartAC(state, action) {
            state.priceColumnForAllChart.spm = action.payload
        },

        changeSpmPriceColumnForCityChartAC(state, action) {
            state.priceColumnCityChart.spm = action.payload
        },
        changeCitiesPriceColumnForCityChartAC(state, action) {
            state.priceColumnCityChart.cities = action.payload
        },
        collapseAC(state, action) {
            state.collapse = action.payload
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDataPrice.fulfilled, (state, action) => {
                state.priceLineChart.data = action.payload
            })
            .addCase(fetchDataPrice.rejected, (state, action: any) => {
                state.error = action.payload.status
            })
            .addCase(fetchDataAllPrice.fulfilled, (state, action) => {
                state.priceColumnForAllChart.data = action.payload[0]
                state.priceColumnForAllChart.orderSpm = action.payload[1]
            })
            .addCase(fetchDataAllPrice.rejected, (state, action: any) => {
                state.error = action.payload.status
            })
            .addCase(fetchDataCityPrice.fulfilled, (state, action) => {
                state.priceColumnCityChart.data = action.payload[0]
                //state.priceColumnCityChart.cities = action.payload[1]
            })
            .addCase(fetchDataCityPrice.rejected, (state, action: any) => {
                state.error = action.payload.status
            })
    }
})


export const priceReducer = slice.reducer
export const {
    changePriceLineChartAC,
    selectSpmPriceForLineChartAC,
    changePriceColumnForAllChartAC,
    changeColumnCityChartAC,
    changeSpmPriceColumnForAllChartAC,
    changeSpmPriceColumnForCityChartAC,
    changeCitiesPriceColumnForCityChartAC,
    collapseAC
} = slice.actions