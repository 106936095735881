export const tableTitle = [
    'Категория 1 уровня',
    'Количество товаров Ozon',
    'Количество товаров WB',
    'Количество товаров ЯМ',
    'Количество товаров AE',
]

export const namesForTotalTable = ['Wildberries', 'Ozon', 'Яндекс.Маркет', 'AliExpress', 'Мегамаркет']

export const formatGrade = (num:number) => new Intl.NumberFormat('ru-RU').format(num)