import {createSlice} from "@reduxjs/toolkit";
import {defaultTime, defaultTimeForCategories} from "../common/optionsForDate";
import {OptionsType} from "../common/types";
import {defData} from "../common/optionsForSwitches";
import {defValueForMarketPlaces, defValueForSuperMarkets} from "../common/optionsForCheckBoxes";


export const slice = createSlice({
    name: 'options',
    initialState: {
        platform: 'stocks',
        chart: 'Рынок, темпы',
        time: defaultTime,
        timeForCategories: defaultTimeForCategories,
        zoomedTime: defaultTime,
        category: 'marketplaces',
        marketPlacesNames: defValueForMarketPlaces,
        supermarketsNames: defValueForSuperMarkets,
        SOV: [''],
        filter: defData,
        table: false,
        visiblePDF: true,
        changeData: false,
        hidden: false,
        switches: {
            SOV: false,
            shares: true,
            selloff: false,
            categories: false,
            sharesChecked: defValueForMarketPlaces
        },
        valuesCharts: {
            maxSOV: null,
            minSOV: null,
            maxOther: null,
            minOther: null,
            maxMarket: null,
            minMarket: null,
        },
        yaxis: [],
        categoriesCheckboxesVisible: true

    } as OptionsType,
    reducers: {
        choiceOfPlatformAC(state, action) {
            state.platform = action.payload
        },
        setYaxisAC(state, action) {
            state.yaxis = action.payload
        },
        setValueChartsAC(state, action) {
            state.valuesCharts = action.payload
        },
        selectChartAC(state, action) {
            state.chart = action.payload
        },
        changeTimeValue(state, action) {
            state.time = [action.payload[0], action.payload[1]]
        },
        changeTimeCategories(state,action) {
            state.timeForCategories = action.payload
        },
        zoomAC(state, action) {
            state.zoomedTime = action.payload
        },
        selectCategoryAC(state, action) {
            state.category = action.payload
        },
        selectMarketPlacesCheckBoxAC(state, action) {
            state.marketPlacesNames = action.payload
        },
        selectSuperMarketsCheckBoxAC(state, action) {
            state.supermarketsNames = action.payload
        },
        selectSOVAC(state, action) {
            state.SOV = action.payload
        },
        filterAC(state, action) {
            state.filter = action.payload
        },
        switchSOVAC(state, action) {
            state.switches.SOV = action.payload
        },
        switchSharesAC(state, action) {
            state.switches.shares = action.payload
        },
        switchSelloffAC(state, action) {
            state.switches.selloff = action.payload
        },
        switchCategoriesAC(state, action) {
            state.switches.categories = action.payload
        },
        sharesCheckedAC(state, action) {
            state.switches.sharesChecked = action.payload
        },
        setTableAC(state, action) {
            state.table = action.payload
        },
        visiblePDFAC(state, action) {
            state.visiblePDF = action.payload
        },
        changeDataFactorAC(state, action) {
            state.changeData = action.payload
        },
        hiddenChartAC(state, action) {
            state.hidden = action.payload
        },
        checkBoxesCategoriesAC(state, action) {
            state.categoriesCheckboxesVisible = action.payload
        },

    },
})

export const optionsReducer = slice.reducer
export const {
    selectChartAC,
    changeTimeValue,
    changeTimeCategories,
    zoomAC,
    selectCategoryAC,
    selectMarketPlacesCheckBoxAC,
    selectSuperMarketsCheckBoxAC,
    selectSOVAC,
    filterAC,
    switchSOVAC,
    switchSharesAC,
    switchCategoriesAC,
    sharesCheckedAC,
    switchSelloffAC,
    setTableAC,
    visiblePDFAC,
    setValueChartsAC,
    setYaxisAC,
    choiceOfPlatformAC,
    changeDataFactorAC,
    hiddenChartAC,
    checkBoxesCategoriesAC
} = slice.actions