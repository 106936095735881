import React, {useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {
    bannerStatus, loadingBanners,
    selectBanner,
    selectCountProd,
    selectFilter,
    selectFilterTitle, selectSlide,
    selectSortTitle,
    selectTableContent, selectTime,
    selectZoomedTime, setError,
    visiblePDF
} from "../../../common/selectors";
import {DataTableResType} from "../../../common/types";
import dayjs from "dayjs";
import {
    clearStatusAC,
    fetchBanners,
    selectParamsAC,
    selectSlideAC,
    selectSortTitleAC
} from "../../../redux/tableReducer";
import {TableComponent} from "../tableComponent/tableComponent";
import {plainOptionsMps} from "../../../common/optionsTableShares";
import styles from "./tableMps.module.sass";
import {formatGrade} from "../../../common/optionsForCategories/table";
import {Button, Popover} from "antd";
import {CloseCircleOutlined, VerticalAlignBottomOutlined} from "@ant-design/icons";
import {Carousel} from "../carousel/carousel";
import {Uploader} from "../../options/uploader/uploader";
import {current} from "@reduxjs/toolkit";
import {Spinner} from "../../../common/spin";
import {setErrorAC} from "../../../redux/authReducer";


const titleTableMps: {[index: string]:string} = {
    name_sale: 'Наименование',
    //count_categories: 'Кол-во категорий',
    count_products: 'Кол-во товаров',
    average_percent: 'Анонсируемая скидка',
    get_date_row: 'Дата начала',
    to_date: 'Дата завершения'
}

export const TableMarketplaces = () => {

    const dispatch = useAppDispatch()

    const tableContent = useAppSelector(selectTableContent)
    const filter = useAppSelector(selectFilter)
    const zoom = useAppSelector(selectZoomedTime)
    const time = useAppSelector(selectTime)
    const sortTitle = useAppSelector(selectSortTitle)
    const filterTitle = useAppSelector(selectFilterTitle)
    const countProd = useAppSelector(selectCountProd)
    const visible = useAppSelector(visiblePDF)
    const banner = useAppSelector(selectBanner)
    const status = useAppSelector(bannerStatus)
    const slideState = useAppSelector(selectSlide)
    const loading = useAppSelector(loadingBanners)

    const sortTitleFunc = (title:string) => {
        if (sortTitle.name !== title) {
            dispatch(selectSortTitleAC({...sortTitle, name: title, direction: true}))
        }
        if (sortTitle.name === title) {
            dispatch(selectSortTitleAC({...sortTitle, name: title, direction: !sortTitle.direction}))
        }

    }

    const filteredTableContent = tableContent
        .filter((el: DataTableResType) => filter.includes(el.spm))
        .filter((el) => filterTitle.includes(el.spm))
        /*.filter((el) => {
            if (!countProd && el.spm !== 'AliExpress') {
               return  el.count_products! >= 2000000 || el.is_sec_field

            }
            if (!countProd && el.spm === 'AliExpress') {
                return  el.count_products! >= 20000 || /!*el.name_sale.toLowerCase().includes('электроника') || el.name_sale.toLowerCase().includes('техника')*!/ el.is_sec_field
            }
            return el
        })*/
        .sort((a,b) => dayjs(b.get_date_row).valueOf() - dayjs(a.get_date_row).valueOf())
        .filter((el) => {
            return (el.get_date_row < zoom[0]! && el.to_date >= zoom[0]!) ||
                (el.get_date_row >= zoom[0]! && el.get_date_row <= zoom[1]! && el.to_date >= zoom[0]! && el.to_date <= zoom[1]!) ||
                (el.get_date_row <= zoom[1]! && el.to_date >= zoom[1]!)
        } /*(!(el.get_date_row < zoom[0]! && el.to_date < zoom[0]!) || !(el.get_date_row > zoom[1]!))*/)

    function customSort(a:DataTableResType[], s:string[]) {
        return a.sort(function(x1, x2) {
            let i1 = s.indexOf(x1.spm),
                i2 = s.indexOf(x2.spm);
            return i1 < 0 ? 1 : i2 < 0 ? -1 : i1 - i2;
        })
    }

    customSort(filteredTableContent, plainOptionsMps);


    const customSort1 = (a:DataTableResType[],b:string[]) => {

        let result:DataTableResType[] = []
        b.forEach((el) => {
            a.forEach((item) => el === item.spm ? result.push(item) : item)
        })

        return result
    }

    const sortTableContent = filteredTableContent
        .sort((a,b) => {
            if (sortTitle.name === 'spm') {
                customSort1(filteredTableContent, plainOptionsMps)
            }
            if (sortTitle.name === 'name_sale' && sortTitle.direction) {
                return  a.name_sale.localeCompare(b.name_sale)
            }
            if (sortTitle.name === 'name_sale' && !sortTitle.direction) {
                return  b.name_sale.localeCompare(a.name_sale)
            }
            if (sortTitle.name !== 'name_sale' && sortTitle.direction) {
                return dayjs(a[sortTitle.name]).valueOf() - dayjs(b[sortTitle.name]).valueOf()
            }
            if (sortTitle.name !== 'name_sale' && !sortTitle.direction) {
                return dayjs(b[sortTitle.name]).valueOf() - dayjs(a[sortTitle.name]).valueOf()
            }
            return dayjs(a[sortTitle.name]).valueOf() - dayjs(b[sortTitle.name]).valueOf()
        })

    const [id, setId] = useState(0)

    const [block, setBlock] = useState(false)

    const [slide, setSlide] = useState<number>(0)

    const [open, setOpen] = useState(false)

    const onClickId = (idRow: number,
                       name_sale: string,
                       spm: string,
                       from_date: string,
                       to_date: string,
                       from_date_sale: string,
                       to_date_sale: string
    ) => {
        //dispatch(clearStatusAC(0))
        //dispatch(fetchBanners({name_sale: 'Климат под контролем ', spm: 'Мегамаркет',  from_date: '2024-01-01', to_date: '2024-12-01', from_date_sale: '2024-04-16', to_date_sale: '2024-04-16'}))
        dispatch(fetchBanners({name_sale, spm: spm,  from_date, to_date, from_date_sale, to_date_sale}))
        dispatch(selectParamsAC({name_sale, spm: spm,  from_date, to_date, from_date_sale, to_date_sale}))
        setId(idRow)
        setSlide(0)
        setOpen(!open)
        //dispatch(setErrorAC(null))
    }



    const hide = () => {
        setBlock(false);
        setId(0);
        //dispatch(setErrorAC(null))
    };

    const onClickTableItem = () => {
        setId(0);
        setBlock(!block)
    }



    const carouselHandler = (current: number) => {
        setSlide(current)
        dispatch(selectSlideAC(current))
    }


    const tableRow = sortTableContent.map((el: DataTableResType, index) =>
        <Popover
            key={index}
            placement="left"
            content={
            <div >
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{width: '85%'}}>{el.name_sale}</div>
                    {/*{banner.urls[slide]&&<Uploader
                                                   currentBanner={banner.urls[slide]}
                                                   slide={slideState}

                    />}*/}
                    {/*<Button type={"link"} icon={<VerticalAlignBottomOutlined />} onClick={uploadHandler} />*/}
                    <Button type={"link"} icon={<CloseCircleOutlined />} onClick={hide} />
                </div>
                {loading === 'loading' ? <Spinner/> :
                    <Carousel data={banner} callBack={carouselHandler} slide={slideState} open={open}/>}

            </div>

        }
            //title={el.name_sale}
            trigger="click"
            open={id === el.id &&block}
            onOpenChange={onClickTableItem}
        >
            <div  key={index} style={{backgroundColor: el.colors, color: el.text, cursor: 'pointer', opacity: id === el.id || !block ? 1 : .5}} className={styles.tableRow}>
                {/*onClick={() => onClickId(el.id, el.name_sale, el.spm, time[0], time[1], el.get_date_row.toString(), el.to_date.toString())}*/}
                <div  className={styles.tableItem}>{el.name_sale}</div>
                {/*<div className={styles.tableItem}>
                <span
                    className={styles.titleItem}>{titleTableMps.count_categories}
                </span>
                    {el.count_categories === 0?
                        <span>
                        -
                        </span>
                        :
                        <span>
                        {formatGrade(+el.count_categories)}
                    </span>
                    }
                </div>*/}
                <div className={styles.tableItem}>
                <span
                    className={styles.titleItem}>{titleTableMps.count_products}
                </span>
                    {el.count_products === 0?
                        <span>
                        -
                        </span>
                        :
                        <span>
                        {formatGrade(el.count_products!)}
                    </span>
                    }
                </div>
                <div className={styles.tableItem}><span
                    className={styles.titleItem}>{titleTableMps.average_percent}</span><span>{el.average_percent}</span></div>
                <div className={styles.tableItem}><span
                    className={styles.titleItem}>{titleTableMps.get_date_row}</span><span>{el.get_date_row}</span></div>
                <div className={styles.tableItem}><span
                    className={styles.titleItem}>{titleTableMps.to_date}</span><span>{el.to_date}</span></div>
            </div>
        </Popover>
        )

    return (
        <>

            <TableComponent
                name={'marketplaces'}
                titleTable={titleTableMps}
                tableContent={tableContent}
                filter={filter}
                zoom={zoom}
                sortTitle={sortTitle}
                filterTitle={filterTitle}
                countProd={countProd}
                visible={visible}
                sortTitleFunc={sortTitleFunc}
                filteredTableContent={filteredTableContent}
                sortTableContent={sortTableContent}
                plainOptions={plainOptionsMps}
                tableRow={tableRow}
            />
        </>
    );
};

