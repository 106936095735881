import React from 'react';
import styles from './chartsPage.module.sass'
import {Options} from "../../components/options";
import {Content} from "../../components/content";

export const ChartsPage = () => {
    return (
        <div  className={styles.section}>
            <div>
                <Options/>
            </div>
            <Content/>
        </div>
    );
};

