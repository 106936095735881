import React from 'react';
import {Checkbox, CheckboxProps} from 'antd';
import {spmToCities} from "../../../../common/optionsForCheckBoxes";
import styles from "../checkboxesGroupPrice/checkboxesGroupPrice.module.sass";

type PropsType = {
    list: string[]
    value: string
    callBack: (checkedValues: any) => void
    cities: string[]
}

export const CheckboxesPrice = ({callBack, value, cities}: PropsType) => {

    const onChange: CheckboxProps['onChange'] = (e) => {
        const checkedValues = e.target.value
        callBack(checkedValues)
    };
    const worksDataSpm: string[] = []
    spmToCities.map((city) => {
        return cities.includes(city.city) ? city.spm.forEach((spm) => !worksDataSpm.includes(spm) ? worksDataSpm.push(spm) : spm) : city
    })

    const filteredWorkDataSpm = Array.from(new Set(worksDataSpm))

    const checkBoxes =  filteredWorkDataSpm.map((el) => <Checkbox key={el} onChange={onChange} checked={el === value} value={el}  className={styles.item}>
        <div className={styles.label}>
            {el}
        </div>
    </Checkbox>)

    return (
        <div className={styles.checkbox}>
            {checkBoxes}
        </div>
    );
};

