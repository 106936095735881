import axios from "axios";
import {HOST} from "../common/constants";
import {DataCategoriesRequestType, DataRequestType, DataTableRequestType} from "../common/types";

const instance = axios.create({
    baseURL: HOST,
})


instance.interceptors.request.use(
    request => {

        request.headers.Authorization = `JWT ${localStorage.getItem('jwt')}`
        request.headers['Content-Type'] = 'multipart/form-data'

        return request
    },
    error => {
        console.log(error)
    }
);

export const dataApi = {
    getData(params: DataRequestType){
        return instance.get(`graphs/`, {params})
    },
    getDataTable(params: DataTableRequestType){
        return instance.get(`tables/`, {params})
    },
    getDataTableSpm(params: DataTableRequestType){
        return instance.get(`tables-supermarket/`, {params})
    },
    getDataCategories(params: DataCategoriesRequestType){
        return instance.get(`categories/`, {params})
    },
    getDataSale(params: DataCategoriesRequestType){
        return instance.get(`gsale/`, {params})
    },
    /*getDataPrice(params: DataCategoriesRequestType){
        return instance.get(`comparison-item/`, {params})
    }*/
}



