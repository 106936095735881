import React, {useRef, useState} from 'react';
import {TableComponent} from "../tableComponent/tableComponent";
import {plainOptionsSpm} from "../../../common/optionsTableShares";
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {
    bannerStatus, loadingBanners,
    selectBanner,
    selectCountProd,
    selectFilter,
    selectFilterTitle, selectSlide,
    selectSortTitle,
    selectTableContentForSpm, selectTime,
    selectZoomedTime,
    visiblePDF
} from "../../../common/selectors";
import {fetchBanners, selectParamsAC, selectSlideAC, selectSortTitleAC} from "../../../redux/tableReducer";
import {DataTableSpmResType} from "../../../common/types";
import dayjs from "dayjs";
import styles from "./tableSpm.module.sass";
import {Uploader} from "../../options/uploader/uploader";
import {Button, Popover} from "antd";
import {CloseCircleOutlined, VerticalAlignBottomOutlined} from "@ant-design/icons";
import {Spinner} from "../../../common/spin";
import {Carousel} from "../carousel/carousel";


const titleTableSpm: {[index: string]:string} = {
    name_sale: 'Наименование',
    spm: 'Супермаркет',
    average_percent: 'Размер скидки',
    count_categories: 'Категории(SKU)',
    get_date_row: 'Дата начала',
    to_date: 'Дата завершения',
}

export const TableSupermarkets = () => {

    const dispatch = useAppDispatch()

    /*const tableContentAlways = useAppSelector(selectTableContentForSpmAlways)
    const tableContentWeekly = useAppSelector(selectTableContentForSpmWeekly)
    const tableContent = useAppSelector(selectTableContentForSpmOther)*/

    const tableContent = useAppSelector(selectTableContentForSpm)

    const filter = useAppSelector(selectFilter)
    const zoom = useAppSelector(selectZoomedTime)

    const sortTitle = useAppSelector(selectSortTitle)
    const filterTitle = useAppSelector(selectFilterTitle)
    const countProd = useAppSelector(selectCountProd)
    const visible = useAppSelector(visiblePDF)
    const banner = useAppSelector(selectBanner)
    const status = useAppSelector(bannerStatus)
    const slideState = useAppSelector(selectSlide)
    const loading = useAppSelector(loadingBanners)
    const time = useAppSelector(selectTime)


    const sortTitleFunc = (title:string) => {
        if (sortTitle.name !== title) {
            dispatch(selectSortTitleAC({...sortTitle, name: title, direction: true}))
        }
        if (sortTitle.name === title) {
            dispatch(selectSortTitleAC({...sortTitle, name: title, direction: !sortTitle.direction}))
        }

    }

    const filteredContent = (content: DataTableSpmResType[]) => {
        return content
            .filter((el) => plainOptionsSpm.includes(el.spm))
            .filter((el) => filterTitle.includes(el.spm))
            .sort((a, b) => dayjs(b.get_date_row).valueOf() - dayjs(a.get_date_row).valueOf())
            .filter((el) => {
                return (el.get_date_row < zoom[0]! && el.to_date >= zoom[0]!) ||
                    (el.get_date_row >= zoom[0]! && el.get_date_row <= zoom[1]! && el.to_date >= zoom[0]! && el.to_date <= zoom[1]!) ||
                    (el.get_date_row <= zoom[1]! && el.to_date >= zoom[1]!)
            }/*!(el.get_date_row < zoom[0]! && el.to_date < zoom[0]!) || (el.get_date_row > zoom[1]! && el.to_date > zoom[1]!)*/)
    }

    /*const filtContentAlways = filteredContent(tableContentAlways)
    const filtContentWeekly = filteredContent(tableContentWeekly)*/
   /* const filtContent = filteredContent(tableContent)*/

    /*const filterContent = filtContentAlways.concat(filtContentWeekly,filtContent)*/

    const filterContent = filteredContent(tableContent)

    const sortContent = (filtCont: DataTableSpmResType[]) => {
        return  filtCont
        .sort((a,b) => {
            if (sortTitle.name === 'spm' && sortTitle.direction&& a.spm !== null && b.spm !== null) {
                return  a.spm.localeCompare(b.spm)
            }
            if (sortTitle.name === 'spm' && !sortTitle.direction&& a.spm !== null && b.spm !== null) {
                return  b.spm.localeCompare(a.spm)
            }

            if (sortTitle.name === 'name_sale' && sortTitle.direction&& a.name_sale !== null && b.name_sale !== null) {
                return  a.name_sale.localeCompare(b.name_sale)
            }
            if (sortTitle.name === 'name_sale' && !sortTitle.direction&& a.name_sale !== null && b.name_sale !== null) {
                return  b.name_sale.localeCompare(a.name_sale)
            }

            if (sortTitle.name === 'average_percent' && sortTitle.direction&& a.average_percent !== null && b.average_percent !== null) {
                return  a.average_percent.localeCompare(b.average_percent)
            }
            if (sortTitle.name === 'average_percent' && !sortTitle.direction&& a.average_percent !== null && b.average_percent !== null) {
                return  b.average_percent.localeCompare(a.average_percent)
            }

            if (sortTitle.name === 'count_categories' && sortTitle.direction && a.count_categories !== null && b.count_categories !== null) {
                return  a.count_categories.localeCompare(b.count_categories)
            }
            if (sortTitle.name === 'count_categories' && !sortTitle.direction && a.count_categories !== null && b.count_categories !== null) {
                return  b.count_categories.localeCompare(a.count_categories)
            }
            if (sortTitle.name === 'to_date' && sortTitle.direction && a.dateInfo !== null && b.dateInfo !== null) {
                return  a.dateInfo.localeCompare(b.dateInfo)
            }
            if (sortTitle.name === 'to_date' && !sortTitle.direction && a.dateInfo !== null && b.dateInfo !== null) {
                return  b.dateInfo.localeCompare(a.dateInfo)
            }
            if (sortTitle.name !== 'name_sale' && sortTitle.direction) {
                return dayjs(a[sortTitle.name]).valueOf() - dayjs(b[sortTitle.name]).valueOf()
            }
            if (sortTitle.name !== 'name_sale' && !sortTitle.direction) {
                return dayjs(b[sortTitle.name]).valueOf() - dayjs(a[sortTitle.name]).valueOf()
            }
            return dayjs(a[sortTitle.name]).valueOf() - dayjs(b[sortTitle.name]).valueOf()
        })}

   /* const sortContentAlways = sortContent(filtContentAlways)
    const sortContentWeekly = sortContent(filtContentWeekly)
    const sortContentOther = sortContent(filtContent)*/

    const sortContentTab = sortContent(filterContent)

    //const sortTableContent = sortContentAlways.concat(sortContentWeekly,sortContentOther)
    const [id, setId] = useState(0)

    const [block, setBlock] = useState(false)

    const [slide, setSlide] = useState<number>(0)

    const [open, setOpen] = useState(false)

    const onClickId = (idRow: number,
                       name_sale: string,
                       spm: string,
                       from_date: string,
                       to_date: string,
                       from_date_sale: string,
                       to_date_sale: string
    ) => {
        //dispatch(clearStatusAC(0))
        //dispatch(fetchBanners({name_sale: 'Климат под контролем ', spm: 'Мегамаркет',  from_date: '2024-01-01', to_date: '2024-12-01', from_date_sale: '2024-04-16', to_date_sale: '2024-04-16'}))
        dispatch(fetchBanners({name_sale, spm: spm,  from_date, to_date, from_date_sale, to_date_sale}))
        dispatch(selectParamsAC({name_sale, spm: spm,  from_date, to_date, from_date_sale, to_date_sale}))
        setId(idRow)
        setSlide(0)
        setOpen(!open)
        //dispatch(setErrorAC(null))
    }



    const hide = () => {
        setBlock(false);
        setId(0);
        //dispatch(setErrorAC(null))
    };

    const onClickTableItem = () => {
        setId(0);
        setBlock(!block)
    }



    const carouselHandler = (current: number) => {
        setSlide(current)
        dispatch(selectSlideAC(current))
    }

    const refUpload = useRef<HTMLInputElement>(null)

    const uploadHandler = () => {
        refUpload.current&&refUpload.current.click()
        //dispatch(fetchBanners({name_sale: 'Акции и скидки', spm: 'ozon',  from_date: '2023-09-10', to_date: '2023-12-31', from_date_sale: '2023-09-30', to_date_sale: '2023-12-18'}))
    }
        const tableRow = sortContentTab.map((el, index) =>
            <Popover
                key={index}
                placement="left"
                content={
                    <div >
                        <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{width: '85%'}}>{el.name_sale}</div>
                            {/*{banner.urls[slide]&&<Uploader refUpload={refUpload}
                                                           currentBanner={banner.urls[slide]}
                                                           slide={slideState}

                            />}*/}
                            {/*<Button type={"link"} icon={<VerticalAlignBottomOutlined />} onClick={uploadHandler} />*/}
                            <Button type={"link"} icon={<CloseCircleOutlined />} onClick={hide} />
                        </div>
                        {loading === 'loading' ? <Spinner/> :
                            <Carousel data={banner} callBack={carouselHandler} slide={slideState} open={open}/>}

                    </div>

                }
                //title={el.name_sale}
                trigger="click"
                open={id === el.id &&block}
                onOpenChange={onClickTableItem}
            >
            <div key={index}  style={{backgroundColor: el.colors, color: el.text, cursor: 'pointer', opacity: id === el.id || !block ? 1 : .5}} className={styles.tableRow}>
                {/*onClick={() => onClickId(el.id, el.name_sale, el.spm, time[0], time[1], el.get_date_row.toString(), el.to_date.toString())}*/}
                <div className={styles.tableItem}>{el.name_sale}</div>
                <div className={styles.tableItem}>
                    <span
                        className={styles.titleItem}>{titleTableSpm.spm}
                    </span>

                    <span>
                        {el.spm}
                    </span>

                </div>
                <div className={styles.tableItem}>
                    <span
                        className={styles.titleItem}>{titleTableSpm.average_percent}
                    </span>
                    <span>{el.average_percent}</span>

                </div>
                <div className={styles.tableItem}><span
                    className={styles.titleItem}>{titleTableSpm.count_categories}</span><span>{el.count_categories}</span></div>
                <div className={styles.tableItem}><span
                    className={styles.titleItem}>{titleTableSpm.get_date_row}</span><span>{el.get_date_row}</span></div>
                <div className={styles.tableItem}><span
                    className={styles.titleItem}>{titleTableSpm.to_date}</span><span>{el.dateInfo}</span></div>
            </div>
            </Popover>
                )

    return (
        <>
            <TableComponent
                name={'supermarkets'}
                titleTable={titleTableSpm}
                tableContent={tableContent}
                filter={filter}
                zoom={zoom}
                sortTitle={sortTitle}
                filterTitle={filterTitle}
                countProd={countProd}
                visible={visible}
                sortTitleFunc={sortTitleFunc}
                filteredTableContent={filterContent}
                sortTableContent={sortContentTab}
                plainOptions={plainOptionsSpm}
                tableRow={tableRow}
            />
        </>
    );
};
