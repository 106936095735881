import React, {useState} from 'react';
import s from './categoriesTableCharts.module.sass'
import ReactApexChart from "react-apexcharts";
import {Button} from "antd";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import dayjs from "dayjs";
import {
    choiceOfPlatform,
    dataForCharts,
    loadingCategoriesCharts,
    selectTableChartCategories,
    selectTime,
    selectTimeForCategories,
    visiblePDF
} from "../../../../common/selectors";
import {tableChartCategoriesAC} from "../../../../redux/categoriesReducer";
import {
    formatDateForCategories,
    getPeriod,
    getTimeCatToTime,
    periodLineChart,
    valueOf
} from "../../../../common/optionsForDate";
import {Spinner} from "../../../../common/spin";
import {formatGrade} from "../../../../common/optionsForCategories/table";
import {offsetXLabels, settingsColors} from "../../../../common/optionsForCharts/visualSettings";
import {marketPlacesColors} from "../../../../common/optionsForColors";
import {DELTA, FONT_SIZE_DATA_LABELS} from "../../../../common/constants";
import {dataType} from "../../../../common/types";
import {weekAnnotations} from "../../../../common/optionsForCharts/weekAnnotations";
import {changeTimeValue} from "../../../../redux/optionsReducer";


export const CategoriesTableCharts = () => {
    const dispatch = useAppDispatch()

    const tableCharts = useAppSelector(selectTableChartCategories)
    const timeCat = useAppSelector(selectTimeForCategories)  // дата для категорий (1 неделя)
    const time = useAppSelector(selectTime)           // главная дата
    const loadingForCharts = useAppSelector(loadingCategoriesCharts)
    const data = useAppSelector(dataForCharts)
    const visible = useAppSelector(visiblePDF)
    const platform = useAppSelector(choiceOfPlatform)


    const [clickAnno, setClickAnno] = useState<any>([])
    const [clickAnno1, setClickAnno1] = useState<any>([])
    const [clickAnnoTotal, setClickAnnoTotal] = useState<any>([])

    const period = getPeriod(time)
    const tickAmountTime = +(period/86400000).toFixed()
    const tickAmountWeek = +(period/604800000).toFixed()
    const offsetXLab = offsetXLabels(period)
    const formTime = valueOf(time)

    const options = {
        chart: {
            //id: 'fb',
            //group: 'social',
            type: 'line' as const,
            events: {
                legendClick: function (chartContext: any, seriesIndex: number, config: any) {
                    return clickAnno.includes(seriesIndex) ? setClickAnno(clickAnno.filter((el: number) => el !== seriesIndex)) : setClickAnno([...clickAnno, seriesIndex])
                },
            },
            height: 300,
            toolbar: {
                show: false,
                tools: {
                    download: false,
                    zoomin: false,
                    zoomout: false,
                }
            },
            zoom: {
                enabled: false
            },
            offsetY: -20
        },
        yaxis: {
            show: false,
            min: 0,
            max: function (max: number) {
                return max
            },
        },
        annotations: {
            xaxis: weekAnnotations(formTime, period)[0],
            points: (function () {
                let points: PointAnnotations[] = []
                let background: string = ''
                let dateOfChart: number[] = []
                let array: number[] = []
                data[0].length&&data[0].map((el) => {
                    el.data.map((item) => array.push(item[1]))
                })

                let max = Math.max(...array)

                let px = max/167
                let deltaUnit = px*DELTA
                data[0].length&&data[0].forEach((el) => {
                    el.data.forEach((item) => !dateOfChart.includes(item[0]) ? dateOfChart.push(item[0]) : item)
                })
                let yAxis: number[][] = []
                dateOfChart.forEach((el) => yAxis.push([]))
                data[0].length&&data[0].forEach((el: dataType) => {
                    el.data.forEach((item) => {
                        dateOfChart.forEach((date, index) => (
                            date === item[0] ? yAxis[index].push(item[1]) : el
                        ))
                    })

                })
                data[0].length&&data[0].forEach((el, index) => {
                    marketPlacesColors.find((i) => {
                        return i.name === el.name ? background = i.color : background = ''
                    })
                    el.data.forEach((item) => {
                        points.push({
                            x: item[0],
                            y: item[1],
                            yAxisIndex: 0,
                            marker: {
                                size: 0
                            },
                            label: {
                                offsetY: (function () {
                                    let res = 10
                                    yAxis.forEach((value) => {
                                        value.sort((a,b) => a - b)
                                        for (let i: number = 1; i < value.length; i++) {
                                            if (item[1] === value[i]) {
                                                let difference = value[i] - value[i - 1]
                                                let difference2 = value[i - 1] - value[i - 2]
                                                let difference3 = value[i - 2] - value[i - 3]
                                                let difference4 = value[i - 3] - value[i - 4]
                                                if (difference <= deltaUnit) {
                                                    res -= 15 - difference/(px)
                                                }
                                                if (i > 1 && difference2 <= deltaUnit && difference < deltaUnit + 15 - difference/(px) * px) {
                                                    res -= 15 - difference2/(px)
                                                }
                                                if (i > 2 && difference3 <= deltaUnit && difference2 < deltaUnit + 15 - difference2/(px) * px + 15 - difference/(px) * px ) {
                                                    res -= 15 - difference3/(px)
                                                }
                                                if (i > 3 && difference4 <= deltaUnit && difference3 < deltaUnit + 15 - difference3/(px) * px + 15 - difference2/(px) * px + 15 - difference/(px) * px) {
                                                    res -= 15 - difference4/(px)
                                                }

                                            }
                                        }
                                    })
                                    return res

                                }()),
                                text: clickAnno.includes(index) ? '' : (item[1]/1000000).toFixed(1),
                                textAnchor: 'middle',
                                borderRadius: 10,
                                borderWidth: .2,
                                style: {
                                    background,
                                    color: background === '#ffe700' ? '#202020' : '#F5F5F5',
                                    fontSize: FONT_SIZE_DATA_LABELS,
                                    fontWeight: 600,
                                    padding: {
                                        left: 5,
                                        right: 5,
                                        top: 0,
                                        bottom: 2,
                                    }
                                }
                            }
                        })
                    })
                })
                return points
            }())

        },
        grid: {
            show: false,
            padding: {   // обязательно!!
                left: 0,
                right: 0
            },
        },
        title: {
            text: tableCharts,
            align: 'center' as const,
            style: {
                color: '#F5F5F5',
                fontSize: '16px',
            }
        },
        subtitle: {
            text: 'Динамика количества товаров',
            align: 'center' as const,
            style: {
                color: '#F5F5F5',
                fontSize: '14px',
            }
        },
        dataLabels: {
            enabled: false,
            formatter: function (val: any, opts: any) {
                return formatGrade(val)
            },
            background: {
                enabled: true,
                borderRadius: 10,
                borderWidth: .2,
            },
        },
        xaxis: {
            type: 'numeric' as const,
            min: periodLineChart(time)[0] ,
            max: periodLineChart(time)[1] ,
            tickAmount: window.innerWidth>1000 ? (period > 5500000000 ? tickAmountWeek : tickAmountTime) : tickAmountWeek,
            tickPlacement: 'between',
            axisTicks: {
                show: period < 6200000000 && true,
                offsetY: 20,
                height: 10,
            },
            axisBorder: {
                show: true,
                color: '#F5F5F5',
                height: 3,
                width: '120%',
                //offsetX: -100,
                offsetY: -1
            },
            labels: {
                show: true,
                /*formatter: function (value: any, timestamp: any, opts: any) {
                    return formatDateForCharts(value)
                },*/
                /*formatter: function (value: any, timestamp: any, opts: any) {
                    return formatDateForCategories(value)
                },*/
                formatter: function (value: any, timestamp: any, opts: any) {
                    return window.innerWidth>1000 ? (period > 5500000000 ? formatDateForCategories(value) : dayjs(value).format('DD')) : formatDateForCategories(value)
                },
                offsetX: offsetXLab,
                offsetY: 3,
                style: {
                    colors: 'white',
                    fontWeight: 14,
                    fontSize: '12',
                },
                //format: 'dd-MM-yy'

            },
            tooltip: {
                enabled: false,
            },
        },
        legend: {
            show: true,
            position: 'top' as 'top',
            offsetY: -10,
            horizontalAlign: 'left' as const,
            showForNullSeries: false,
            fontSize: '14px',
            onItemClick: {
                toggleDataSeries: true
            },
            onItemHover: {
                highlightDataSeries: false
            },
            labels: {
                colors: '#F5F5F5'
            }
        },
        tooltip: {
            enabled: true,
            theme: 'dark',
            followCursor: false,
            style: {
                fontSize: '14px',

            },
            x: {
                show: true,
                formatter: function (value:any) {
                    return formatDateForCategories(value)
                }
            },
            marker: {
                show: true,
            },
            fixed: {
                enabled: true,
                position: 'topRight',
                offsetX: 0,
                offsetY: 0,
            },
            y: {
                formatter: function (val: any, opts: any) {
                    return (val/1000000).toFixed(1)
                },
            }
        },
        colors: settingsColors('marketplaces', data[0])
    }

    const optionsLine2 = {
        chart: {
            //id: 'tw',
            //group: 'social',
            type: 'line' as const,
            events: {
                legendClick: function (chartContext: any, seriesIndex: number, config: any) {
                    return clickAnno1.includes(seriesIndex) ? setClickAnno1(clickAnno1.filter((el: number) => el !== seriesIndex)) : setClickAnno1([...clickAnno1, seriesIndex])
                },
            },
            height: 300,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        subtitle: {
            text: 'Динамика доли категории в общем каталоге',
            align: 'center' as const,
            offsetY: -5,
            style: {
                color: '#F5F5F5',
                fontSize: '14px',
            }
        },
        grid: {
            show: false,
            padding: {
                left: 0,
                right:0
            }
        },
        annotations: {
            xaxis: weekAnnotations(formTime, period)[0],
            points: (function () {
                let points: PointAnnotations[] = []
                let background: string = ''
                let dateOfChart: number[] = []
                let array: number[] = []
                data[2].length&&data[2].map((el) => {
                    el.data.map((item) => array.push(item[1]))
                })

                let max = Math.max(...array)

                let px = max/167       // сколько ед в 1 рх
                let deltaUnit = px*15  // в 15 px, где 15 желаемая минимальная дельта между метками для данного графика
                data[2].length&&data[2].forEach((el) => {
                    el.data.forEach((item) => !dateOfChart.includes(item[0]) ? dateOfChart.push(item[0]) : item)
                })
                let yAxis: number[][] = []
                dateOfChart.forEach((el) => yAxis.push([]))
                data[2].length&&data[2].forEach((el: dataType) => {
                    el.data.forEach((item) => {
                        dateOfChart.forEach((date, index) => (
                            date === item[0] ? yAxis[index].push(item[1]) : el
                        ))
                    })

                })
                data[2].length&&data[2].forEach((el, index) => {
                    marketPlacesColors.find((i) => {
                        return i.name === el.name ? background = i.color : background = ''
                    })
                    el.data.forEach((item) => {
                        points.push({
                            x: item[0],
                            y: item[1],
                            yAxisIndex: 0,
                            marker: {
                                size: 0
                            },
                            label: {
                                offsetY: (function () {
                                    let res = 10
                                    yAxis.forEach((value) => {
                                        value.sort((a,b) => a - b)
                                        for (let i: number = 1; i < value.length; i++) {
                                            if (item[1] === value[i]) {
                                                let difference = value[i] - value[i - 1]
                                                let difference2 = value[i - 1] - value[i - 2]
                                                let difference3 = value[i - 2] - value[i - 3]
                                                let difference4 = value[i - 3] - value[i - 4]
                                                if (difference <= deltaUnit) {
                                                    res -= 15 - difference/(px)
                                                }
                                                if (i > 1 && difference2 <= deltaUnit && difference < deltaUnit + 15 - difference/(px) * px) {
                                                    res -= 15 - difference2/(px)
                                                }
                                                if (i > 2 && difference3 <= deltaUnit && difference2 < deltaUnit + 15 - difference2/(px) * px + 15 - difference/(px) * px ) {
                                                    res -= 15 - difference3/(px)
                                                }
                                                if (i > 3 && difference4 <= deltaUnit && difference3 < deltaUnit + 15 - difference3/(px) * px + 15 - difference2/(px) * px + 15 - difference/(px) * px) {
                                                    res -= 15 - difference4/(px)
                                                }

                                               /* if (value[i - 2]/!*&& difference2 <= deltaUnit*!/ /!*&& difference < deltaUnit +DELTA - difference*!/) {
                                                    res += difference2
                                                }*/
                                               /* else if (difference > deltaUnit) {
                                                    return res
                                                }*/
                                            }
                                        }
                                    })
                                    return res
                                }()),
                                text: clickAnno1.includes(index) ? '' : Math.round(item[1])  + '%' ,
                                textAnchor: 'middle',
                                borderRadius: 10,
                                borderWidth: .2,
                                style: {
                                    background,
                                    color: background === '#ffe700' ? '#202020' : '#F5F5F5',
                                    fontSize: '12px',
                                    fontWeight: 600,
                                    padding: {
                                        left: 5,
                                        right: 5,
                                        top: 0,
                                        bottom: 2,
                                    }
                                }
                            }
                        })
                    })
                })
                return points
            }())

        },
        dataLabels: {
            enabled: false,
            formatter: function (val: any, opts: any) {
                return Math.round(val) + '%'
            },
            background: {
                enabled: true,
                borderRadius: 10,
                borderWidth: .2,
            },
        },
        markers: {
            size: 2,

        },
        xaxis: {
            type: 'numeric' as const,
            min: periodLineChart(time)[0] ,
            max: periodLineChart(time)[1] ,
            tickAmount: window.innerWidth>1000 ? (period > 5500000000 ? tickAmountWeek : tickAmountTime) : tickAmountWeek,
            tickPlacement: 'between',
            axisTicks: {
                show: period < 6200000000 && true,
                offsetY: 20,
                height: 10,
            },
            axisBorder: {
                show: true,
                color: '#F5F5F5',
                height: 3,
                width: '120%',
                //offsetX: -100,
                offsetY: -1
            },
            labels: {
                show: true,
                /*formatter: function (value: any, timestamp: any, opts: any) {
                    return formatDateForCharts(value)
                },*/
                /*formatter: function (value: any, timestamp: any, opts: any) {
                    return formatDateForCategories(value)
                },*/
                formatter: function (value: any, timestamp: any, opts: any) {
                    return window.innerWidth>1000 ? (period > 5500000000 ? formatDateForCategories(value) : dayjs(value).format('DD')) : formatDateForCategories(value)
                },
                offsetX: offsetXLab,
                offsetY: 3,
                style: {
                    colors: 'white',
                    fontWeight: 14,
                    fontSize: '12',
                },
                //format: 'dd-MM-yy'

            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            show: false,
            min: 0,
            max: function (max: number) {
                return max
            },
        },
        legend: {
            show: true,
            position: 'top' as 'top',
            offsetY: -10,
            horizontalAlign: 'left' as const,
            showForNullSeries: false,
            fontSize: '14px',
            onItemClick: {
                toggleDataSeries: true
            },
            onItemHover: {
                highlightDataSeries: false
            },
            labels: {
                colors: '#F5F5F5'
            }
        },
        tooltip: {
            enabled: true,
            theme: 'dark',
            followCursor: false,
            style: {
                fontSize: '14px',

            },
            x: {
                show: true,
                formatter: function (value:any) {
                    return formatDateForCategories(value)
                }
            },
            marker: {
                show: true,
            },
            fixed: {
                enabled: true,
                position: 'topRight',
                offsetX: 0,
                offsetY: 0,
            },
            y: {
                formatter: function (val: any, opts: any) {
                    return Math.round(val) + '%'
                },
            }
        },
        colors: settingsColors('marketplaces', data[0])
    }

    const optionsTotal = {
        chart: {
            type: 'line' as const,
            events: {
                legendClick: function (chartContext: any, seriesIndex: number, config: any) {
                    return clickAnnoTotal.includes(seriesIndex) ? setClickAnnoTotal(clickAnnoTotal.filter((el: number) => el !== seriesIndex)) : setClickAnnoTotal([...clickAnnoTotal, seriesIndex])
                },
            },
            height: 600,
            toolbar: {
                show: false,
                tools: {
                    download: false
                }
            },
            zoom: {
                enabled: false
            }
        },
        title: {
            text: 'Динамика количества товаров в каталоге',
            align: 'center' as const,
            style: {
                color: '#F5F5F5',
                fontSize: '16px',
            }
        },
        grid: {
            show: false,
            padding: {
                left: 0,
                right:0
            }
        },
        annotations: {
            xaxis: weekAnnotations(formTime, period)[0],
            points: (function () {
                let points: PointAnnotations[] = []
                let background: string = ''
                let dateOfChart: number[] = []
                let array: number[] = []
                data[1].length&&data[1].map((el) => {
                    el.data.map((item) => array.push(item[1]))
                })

                let max = Math.max(...array)

                let px = max/490
                let deltaUnit = px*DELTA
                data[1].length&&data[1].forEach((el) => {
                    el.data.forEach((item) => !dateOfChart.includes(item[0]) ? dateOfChart.push(item[0]) : item)
                })
                let yAxis: number[][] = []
                dateOfChart.forEach((el) => yAxis.push([]))
                data[1].length&&data[1].forEach((el: dataType) => {
                    el.data.forEach((item) => {
                        dateOfChart.forEach((date, index) => (
                            date === item[0] ? yAxis[index].push(item[1]) : el
                        ))
                    })

                })
                data[1].length&&data[1].forEach((el, index) => {
                    marketPlacesColors.find((i) => {
                        return i.name === el.name ? background = i.color : background = ''
                    })
                    el.data.forEach((item) => {
                        points.push({
                            x: item[0],
                            y: item[1],
                            yAxisIndex: 0,
                            marker: {
                                size: 0
                            },
                            label: {
                                offsetY: (function () {
                                    let res = 10
                                    yAxis.forEach((value) => {
                                        value.sort((a,b) => a - b)
                                        for (let i: number = 1; i < value.length; i++) {
                                            if (item[1] === value[i]) {
                                                let difference = value[i] - value[i - 1]
                                                if (difference <= deltaUnit) {
                                                    return res -= 15 - difference/(px)
                                                }
                                                else if (difference > deltaUnit) {
                                                    return res
                                                }

                                            }
                                        }
                                    })
                                    return res

                                }()),
                                text: clickAnnoTotal.includes(index) ? '' : (item[1]/1000000).toFixed(1),
                                textAnchor: 'middle',
                                borderRadius: 10,
                                borderWidth: .2,
                                style: {
                                    background,
                                    color: background === '#ffe700' ? '#202020' : '#F5F5F5',
                                    fontSize: '12px',
                                    fontWeight: 600,
                                    padding: {
                                        left: 5,
                                        right: 5,
                                        top: 0,
                                        bottom: 2,
                                    }
                                }
                            }
                        })
                    })
                })
                return points
            }())

        },
        dataLabels: {
            enabled: false,
            formatter: function (val: any, opts: any) {
                return formatGrade(val)
            },
            background: {
                enabled: true,
                borderRadius: 10,
                borderWidth: .2,
            },
        },
        xaxis: {
            type: 'numeric' as const,
            min: periodLineChart(time)[0] ,
            max: periodLineChart(time)[1] ,
            tickAmount: window.innerWidth>1000 ? (period > 5500000000 ? tickAmountWeek : tickAmountTime) : tickAmountWeek,
            tickPlacement: 'between',
            axisTicks: {
                show: period < 6200000000 && true,
                offsetY: 20,
                height: 10,
            },
            axisBorder: {
                show: true,
                color: '#F5F5F5',
                height: 3,
                width: '120%',
                //offsetX: -100,
                offsetY: -1
            },
            labels: {
                show: true,
                /*formatter: function (value: any, timestamp: any, opts: any) {
                    return formatDateForCharts(value)
                },*/
                /*formatter: function (value: any, timestamp: any, opts: any) {
                    return formatDateForCategories(value)
                },*/
                formatter: function (value: any, timestamp: any, opts: any) {
                    return window.innerWidth>1000 ? (period > 5500000000 ? formatDateForCategories(value) : dayjs(value).format('DD')) : formatDateForCategories(value)
                },
                offsetX: offsetXLab,
                offsetY: 3,
                style: {
                    colors: 'white',
                    fontWeight: 14,
                    fontSize: '12',
                },
                //format: 'dd-MM-yy'

            },
            tooltip: {
                enabled: false,
            },
        },
        legend: {
            show: true,
            position: 'top' as 'top',
            offsetY: -10,
            horizontalAlign: 'left' as const,
            showForNullSeries: false,
            fontSize: '14px',
            onItemClick: {
                toggleDataSeries: true
            },
            onItemHover: {
                highlightDataSeries: false
            },
            labels: {
                colors: '#F5F5F5'
            }
        },
        yaxis: {
            show: false,
            min: 0,
            max: function (max: number) {
                return max
            },
        },
        tooltip: {
            enabled: true,
            theme: 'dark',
            followCursor: false,
            style: {
                fontSize: '14px',

            },
            x: {
                show: true,
                formatter: function (value:any) {
                    return formatDateForCategories(value)
                }
            },
            marker: {
                show: true,
            },
            fixed: {
                enabled: true,
                position: 'topRight',
                offsetX: 0,
                offsetY: 0,
            },
            y: {
                formatter: function (val: any, opts: any) {
                    return (val/1000000).toFixed(1)
                },
            }
        },
        colors: settingsColors('marketplaces', data[0])
    }

    const onClickHandler = () => {
        dispatch(changeTimeValue(getTimeCatToTime(timeCat)))
    }

    const back = () => {
        dispatch(tableChartCategoriesAC(''))

    }



    return (
        <>
            {loadingForCharts === 'loading' ? <Spinner/> :
                <>
                    <div className={s.navigation} style={{opacity: visible ? 1 : 0}}>
                        <Button type={'link'} className={s.button}
                                onClick={back}>{platform === 'categories' ? 'Показать всю таблицу' : 'Показать весь каталог'}</Button>
                        {/*{platform === 'categories' && <Button type={'link'} className={s.button} onClick={onClickHandler}>Назад к исходной дате</Button>}*/}
                    </div>
                    <div className={s.charts}>
                        <div className={s.block} style={{display: visible ? 'none' : 'block'}}></div>
                        {
                            tableCharts && tableCharts !== 'Динамика количества товаров в каталоге' ?
                                <div /*id={"wrapper"}*/>
                                    <div>
                                        {data.length &&
                                            <ReactApexChart options={options} series={data[0]} type="line" height={300}
                                                            style={{color: '#FFFFFF'}}/>}
                                    </div>

                                    <div>
                                        {data.length &&
                                            <ReactApexChart options={optionsLine2} series={data[2]} type="line"
                                                            height={300} style={{color: '#FFFFFF'}}/>}
                                    </div>

                                </div> :
                                <div>
                                    {data.length &&
                                        <ReactApexChart options={optionsTotal} series={data[1]} type="line" height={600}
                                                        style={{color: '#FFFFFF'}}/>}
                                </div>
                        }
                    </div>
                </>}
        </>
    );
};

