import React, {useState} from 'react';
import {DatePicker, DatePickerProps, Space} from "antd";
import {RangePickerProps} from "antd/es/date-picker";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/ru_RU";
import dateIcon from "../../../../assets/datePickerIcon.svg";
import styles from "../../datePicker/datePicker.module.sass";
import closeIcon from "../../../../assets/close.svg";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {selectTime} from "../../../../common/selectors";

type DatePickerPricePropsType = {
    defaultTime: string[]
    callBack: (date: [string, string] | string) => void
    sessionLabel: [string, string]
}

export const DatePickerPrice = ({defaultTime, callBack, sessionLabel}: DatePickerPricePropsType) => {

    const dispatch = useAppDispatch()

    const time = useAppSelector(selectTime)

    const { RangePicker } = DatePicker;

    let onChange = (
        value: DatePickerProps['value'] | RangePickerProps['value'],
        dateString: [string, string] | string,
    ) => {
        const start = dayjs(dayjs(dateString[0]).subtract(0, 'day')).format('YYYY-MM-DD')
        const end = dayjs(dayjs(dateString[1]).add(1, 'hour')).format('YYYY-MM-DD')       // настройка на один день
        if (value) {
            sessionStorage.setItem(sessionLabel[0], start)
            sessionStorage.setItem(sessionLabel[1], end)
            callBack(dateString)
            //dispatch(zoomAC([start, end]))
        }

    };

    const [value, setValue] = useState(true)


    let onOpenChange = (open: any) => {
        return open ? setValue(false) : setValue(true)
    }

    function customFormat(value: any) {
        return value.format("YYYY-MM-DD ");
    }

    return (
        <Space direction="vertical" size={12} style={{marginBottom: '10px'}}>
            <RangePicker defaultValue={[dayjs(defaultTime[0]), dayjs(defaultTime[1])]}
                         style={{width: '320px'}}
                         picker={'week'}
                         locale={locale}
                         suffixIcon={<img src={dateIcon} alt={'select'}/>}
                         allowClear={{
                             clearIcon: <img className={styles.closeIcon} src={closeIcon} alt={'clear'}/>
                         }}
                         format={customFormat}
                         onChange={onChange}
                         onOpenChange={onOpenChange}
                //disabledDate={disabledDate}
            />
        </Space>
    );
};

