import React, {memo, useState} from 'react';
import {DatePicker, DatePickerProps, Space} from "antd";
import {RangePickerProps} from "antd/es/date-picker";

import dateIcon from '../../../assets/datePickerIcon.svg'
import closeIcon from '../../../assets/close.svg'
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {changeTimeCategories, changeTimeValue, zoomAC} from "../../../redux/optionsReducer";
import 'dayjs/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

import {
    choiceOfPlatform,
    selectChart,
    selectSwitchCategories,
    selectTableChartCategories,
    selectTime,
    selectTimeForCategories
} from "../../../common/selectors";

import styles from './datePicker.module.sass'
import {getDateForCategories} from "../../../common/optionsForDate";


import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import 'dayjs/locale/zh-cn';

import updateLocale from 'dayjs/plugin/updateLocale';
import {clear} from "../../../redux/categoriesReducer";


dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(updateLocale);
dayjs.updateLocale('zh-cn', {
    weekStart: 1,
});

export const DatePickerComponent = memo(() => {



    const dispatch = useAppDispatch()
    const time = useAppSelector(selectTime)
    const timeCat = useAppSelector(selectTimeForCategories)
    const categories = useAppSelector(selectSwitchCategories)
    const tableCharts = useAppSelector(selectTableChartCategories)
    const platform = useAppSelector(choiceOfPlatform)


    const {RangePicker} = DatePicker;

    let onChange = (
        value: DatePickerProps['value'] | RangePickerProps['value'],
        dateString: [string, string] | string,
    ) => {
        const start = dayjs(dayjs(dateString[0]).day(1).subtract(0, 'day')).format('YYYY-MM-DD')
        const end = dayjs(dayjs(dateString[1]).add(1, 'hour')).format('YYYY-MM-DD')       // настройка на один день
        if (value) {
            sessionStorage.setItem('startDate', start)
            sessionStorage.setItem('endDate', end)
            dispatch(changeTimeValue([start, end]))
            dispatch(zoomAC([start, end]))
        }

    };

    const [value, setValue] = useState(true)


    let onOpenChange = (open: any) => {
       return open ? setValue(false) : setValue(true)
    }

    function customFormat(value: any) {
        return value.format("YYYY-MM-DD ");
    }

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        return current && current < dayjs(time[0]).endOf('day');
    };

    const onChangeCat: DatePickerProps['onChange'] = (date:any, dateString) => {
        if (date) {
            let firstDay = dayjs(date).day(1)
            dispatch(changeTimeCategories(getDateForCategories(firstDay)))
            localStorage.setItem('categoryStartDate',getDateForCategories(firstDay)[0])
            localStorage.setItem('categoryEndDate',getDateForCategories(firstDay)[1])
        }

    };

    return (
        <div >
            <Space direction="vertical" size={12}>

                {platform === 'stocks' || (platform === 'categories' && tableCharts) ?
                <RangePicker defaultValue={[dayjs(time[0]), dayjs(time[1])]}
                             style={{maxWidth: '320px'}}
                             picker={'week'}
                             value={[dayjs(time[0]), dayjs(time[1])]}
                             locale={locale}
                             suffixIcon={<img src={dateIcon} alt={'select'}/>}
                             allowClear={{
                                 clearIcon: <img className={styles.closeIcon} src={closeIcon} alt={'clear'}/>
                             }}
                             format={customFormat}
                             onChange={onChange}
                             onOpenChange={onOpenChange}
                            //disabledDate={disabledDate}
                />:
                <DatePicker
                    defaultValue ={dayjs(timeCat[0]).day(1)}
                    locale={locale}
                    style={{width: '320px'}}
                    picker={'week'}
                    suffixIcon={<img src={dateIcon} alt={'select'}/>}
                    allowClear={{
                        clearIcon: <img className={styles.closeIcon} src={closeIcon} alt={'clear'}/>
                    }}
                    format={'YYYY-MM-DD'}
                    onChange={onChangeCat}
                    onOpenChange={onOpenChange}
                    disabled={!!tableCharts}
                />}
            </Space>
        </div>
    );
});

