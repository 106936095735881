import React, {useEffect} from 'react';
import {ColumnsType} from "antd/es/table";
import {TableProps} from "antd/lib";
import {Button, Table} from "antd";
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {
    dataForCharts,
    selectCategories,
    selectChart,
    selectDataTable,
    selectDataTotalTable,
    selectTableChartCategories,
    selectTimeForCategories
} from "../../../common/selectors";
import {formatGrade} from "../../../common/optionsForCategories/table";
import {EmptyComponent} from "../../../common/empty";

import {CategoriesTableCharts} from "../charts/categoriesTableCharts";
import {fetchDataTableCategories, tableChartCategoriesAC} from "../../../redux/categoriesReducer";
import {getTimeCatToTime} from "../../../common/optionsForDate";
import {changeTimeValue} from "../../../redux/optionsReducer";


type DataType = {
    key: React.Key;
    name: string;
    ozon: number;
    wb: number;
    ym: number;
    ae: number;
}


export const TableCategories = () => {

    const dispatch = useAppDispatch()
    const catData = useAppSelector(selectCategories)
    const chart = useAppSelector(selectChart)
    const tableCarts = useAppSelector(selectTableChartCategories)
    const timeCat = useAppSelector(selectTimeForCategories)
    const dataTable = useAppSelector(selectDataTable)
    const dataTotalTable = useAppSelector(selectDataTotalTable)
    const data = useAppSelector(dataForCharts)


    useEffect(() => {
        dispatch(fetchDataTableCategories(({from_date:timeCat[0], to_date:timeCat[1]})))
    },[dispatch])


    let dataForTable: any = []
    //let dataForTotalTable: any = []

    const dataByGrade = dataTable.map((el: any) => (
        {...el,
            ozon: formatGrade(el.ozon),
            wb: formatGrade(el.wb),
            ym: formatGrade(el.ym),
            ae: formatGrade(el.ae),
            mm: formatGrade(el.mm),
        }
    ))

    catData.length ? dataForTable = dataByGrade : dataForTable = []


    const columnsForTotalTable: ColumnsType<DataType> = [
        {
            title: 'Наименование',
            dataIndex: 'name',
            align: 'center',
        },
        {
            title: 'Категории 1 уровня',
            dataIndex: 'one_level',
            align: 'center',
        },
        {
            title: 'Категорий всех уровней',
            dataIndex: 'all_levels',
            align: 'center',
        },
        {
            title: 'Количество товаров в продаже',
            dataIndex: 'products',
            align: 'center',
        },
    ]
    const columns: ColumnsType<DataType> = [
        {
            title: 'Категория 1 уровня',
            dataIndex: 'name',
        },
        {
            title: 'Количество товаров Ozon',
            align: 'center',
            dataIndex: 'ozon',
            width: 130
        },
        {
            title: 'Количество товаров WB',
            align: 'center',
            dataIndex: 'wb',
            width: 130
        },
        {
            title: 'Количество товаров ЯМ',
            align: 'center',
            dataIndex: 'ym',
            width: 130
        },
        {
            title: 'Количество товаров AE',
            align: 'center',
            dataIndex: 'ae',
            width: 130
        },
        {
            title: 'Количество товаров MM',
            align: 'center',
            dataIndex: 'mm',
            width: 130
        },
    ];

    const onChange: TableProps<DataType>['onChange'] = ( filters, sorter, extra) => {
        console.log('params', filters, sorter, extra);
    };

    let scroll = {}
    chart === 'Сравнение общее' ? scroll = {x: true} : scroll = {x: true, y: '75vh'}

    const rowSelect = (event: any) => {
        const category = event.currentTarget.innerText.replace(/[0-9]/g, '').trim()
        chart === 'Данные 1 уровня'&&dispatch(tableChartCategoriesAC(category))
        dispatch(changeTimeValue(getTimeCatToTime(timeCat)))
    }

    const onClick = () => {
        dispatch(tableChartCategoriesAC('Динамика количества товаров в каталоге'))
        dispatch(changeTimeValue(getTimeCatToTime(timeCat)))
    }

    return (
        <>
            {
                tableCarts && chart === 'Данные 1 уровня' ?
                    data.length&&<CategoriesTableCharts/>
                    :
                    <div>
                        {chart === 'Данные 1 уровня'&&<Button type={'link'} style={{marginBottom: '10px'}} onClick={onClick}>Динамика товаров в каталоге</Button>}
                        {catData.length?<Table style={{cursor: "pointer"}} columns={chart === 'Данные 1 уровня'? columns : columnsForTotalTable}
                                               dataSource={chart === 'Данные 1 уровня'? dataForTable : dataTotalTable}
                                               onChange={onChange}
                                               size={"small"}
                                               pagination={false}
                                               scroll={scroll}
                                               onRow={(record, rowIndex) => {
                                                   return {
                                                       onClick: rowSelect
                                                   };
                                               }}
                        />:<EmptyComponent/>}
                    </div>
            }

        </>
    );
};

