import {combineReducers, configureStore} from "@reduxjs/toolkit";
import thunkMiddleware from 'redux-thunk'
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {chartsReducer} from "./chartsReducer";
import {tableReducer} from "./tableReducer";
import {optionsReducer} from "./optionsReducer";
import {categoriesReducer} from "./categoriesReducer";
import {authReducer} from "./authReducer";
import {priceReducer} from "./priceReducer";
import {saleReducer} from "./saleReducer";
import {mapReducer} from "./mapReducer";
import {pointReducer} from "./pointReducer";
import {newsReducer} from "./newsReducer";

export const rootReducer = combineReducers({
    auth: authReducer,
    charts: chartsReducer,
    options: optionsReducer,
    table: tableReducer,
    categories: categoriesReducer,
    price: priceReducer,
    sales: saleReducer,
    map: mapReducer,
    points: pointReducer,
    news: newsReducer
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false, immutableCheck: false,}),
})

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<AppRootStateType> = useSelector;

export type AppRootStateType = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch