import axios from "axios";
import {HOST_NEWS} from "../common/constants";

const instance = axios.create({
    baseURL: HOST_NEWS,
})


instance.interceptors.request.use(
    request => {

        request.headers.Authorization = `JWT ${localStorage.getItem('jwt')}`
        request.headers['Content-Type'] = 'multipart/form-data'

        return request
    },
    error => {
        console.log(error)
    }
);

export const newsApi = {
    getData(from_date: string, to_date: string){
        return instance.get(`news/?from_date=${from_date}&to_date=${to_date}`)
    },

}