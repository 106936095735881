import React, {memo} from 'react';
import styles from "./buttons.module.sass";
import {Button} from "antd";
import {
    changeTimeValue, checkBoxesCategoriesAC,
    choiceOfPlatformAC,
    selectCategoryAC, selectChartAC,
    selectMarketPlacesCheckBoxAC,
    selectSOVAC, selectSuperMarketsCheckBoxAC, setTableAC,
    sharesCheckedAC, switchCategoriesAC
} from "../../../redux/optionsReducer";
import {SOVForMarketPlaces, SOVForSuperMarkets} from "../../../common/optionsForSwitches";
import {defValueForMarketPlaces, defValueForSuperMarkets} from "../../../common/optionsForCheckBoxes";
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {
    choiceOfPlatform,
    selectCategory,
    selectSwitchCategories,
    selectSwitchShares,
    selectSwitchSOV
} from "../../../common/selectors";
import {selectFilterTitleAC} from "../../../redux/tableReducer";
import {plainOptionsMps, plainOptionsSpm} from "../../../common/optionsTableShares";
import {defaultTime} from "../../../common/optionsForDate";
import {tableChartCategoriesAC} from "../../../redux/categoriesReducer";

export const Buttons = memo(() => {

    const dispatch = useAppDispatch()
    const category = useAppSelector(selectCategory)
    const switchSOV = useAppSelector(selectSwitchSOV)
    const switchShares = useAppSelector(selectSwitchShares)
    const platform = useAppSelector(choiceOfPlatform)

    const onClickPlacesHandler = () => {
        dispatch(selectCategoryAC('marketplaces'))
        dispatch(selectChartAC('Рынок, темпы'))
        switchSOV&&dispatch(selectSOVAC(SOVForMarketPlaces))
        switchShares&&dispatch(selectMarketPlacesCheckBoxAC(defValueForMarketPlaces))
        dispatch(sharesCheckedAC(defValueForMarketPlaces))
        dispatch(selectFilterTitleAC(plainOptionsMps))
        dispatch(changeTimeValue(defaultTime))
        dispatch(choiceOfPlatformAC('stocks'))
        dispatch(switchCategoriesAC(false))
        dispatch(setTableAC(true))
        dispatch(tableChartCategoriesAC(''))
        dispatch(checkBoxesCategoriesAC(false))
    }

    const onClickMarketsHandler = () => {
        dispatch(selectCategoryAC('supermarkets'))
        dispatch(selectChartAC('Рынок, темпы'))
        dispatch(choiceOfPlatformAC('stocks'))
        switchSOV&&dispatch(selectSOVAC(SOVForSuperMarkets))
        switchShares&&dispatch(selectSuperMarketsCheckBoxAC(defValueForSuperMarkets))
        dispatch(sharesCheckedAC(defValueForSuperMarkets))
        dispatch(selectFilterTitleAC(plainOptionsSpm))
        dispatch(changeTimeValue(defaultTime))
        dispatch(switchCategoriesAC(false))
        dispatch(setTableAC(true))
        dispatch(tableChartCategoriesAC(''))
        dispatch(checkBoxesCategoriesAC(false))
    }

    const onClickPriceIndexHandler = () => {
        dispatch(choiceOfPlatformAC('price'))
        dispatch(selectChartAC('PI фактический'))
        dispatch(selectCategoryAC(''))
        dispatch(switchCategoriesAC(false))
        dispatch(tableChartCategoriesAC(''))
        dispatch(checkBoxesCategoriesAC(false))
    }

    const onClickCategoriesHandler = () => {
        dispatch(switchCategoriesAC(true))
        dispatch(choiceOfPlatformAC('categories'))
        dispatch(selectChartAC('Категории'))
        dispatch(selectCategoryAC('marketplaces'))
        dispatch(tableChartCategoriesAC(''))
        dispatch(checkBoxesCategoriesAC(true))
        dispatch(selectMarketPlacesCheckBoxAC(defValueForMarketPlaces))
    }


    return (
        <div className={styles.main}>
            <div className={styles.buttons}>
                <Button className={styles.button} onClick={onClickPlacesHandler}
                        type={platform === 'stocks' && category === 'marketplaces' ? 'default' : 'primary'}>Маркетплейсы</Button>
                <Button className={styles.button} onClick={onClickMarketsHandler}
                        type={platform === 'stocks' && category === 'supermarkets' ? 'default' : 'primary'}>Супермаркеты</Button>
            </div>
            <div className={styles.buttons}>
                <Button className={styles.button} onClick={onClickCategoriesHandler}
                        type={platform === 'categories' ? 'default' : 'primary'}>Категории</Button>
                <Button className={styles.button} onClick={onClickPriceIndexHandler}
                        type={platform === 'price' ? 'default' : 'primary'}>Индекс цен</Button>
            </div>

        </div>
    );
});

