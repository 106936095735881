import React, {useEffect} from 'react';
import {Button, Form, Input} from 'antd';
import {useNavigate} from "react-router-dom";
import {MAIN} from "../../common/constants";
import {LogIn} from "../../redux/authReducer";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {isLoggedIn, setError} from "../../common/selectors";
import {Logo} from "../../components/content/logo";
import styles from './auth.module.sass';


type FieldType = {
    username?: string;
    password?: string;
    remember?: string;
};

export const Auth = () => {

    const log = useAppSelector(isLoggedIn)
    const error = useAppSelector(setError)

    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')
    const dispatch = useAppDispatch()


    useEffect(() => {
        if (log&&!error) {
            navigate(MAIN)
        }
    }, [jwt, navigate, log])

    const onFinish = (values: any) => {
        dispatch(LogIn({username: values.username, password: values.password}))
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <div className={styles.main}>
            <div className={styles.logo}>
                <Logo/>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.form} >
                <div className={styles.item}>
                    <Form
                        name="basic"
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        style={{maxWidth: 600}}
                        initialValues={{remember: true}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"

                    >
                        <Form.Item<FieldType>
                            label="Логин"
                            name="username"
                            rules={[{required: true, message: 'Введите свой логин!'}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item<FieldType>
                            label="Пароль"
                            name="password"
                            rules={[{required: true, message: 'Введите свой пароль!'}]}
                        >
                            <Input.Password/>
                        </Form.Item>


                        <Form.Item wrapperCol={{sm: {offset: 8, span: 16,}}}>
                            <Button type="primary" htmlType="submit" className={styles.button} >
                                Вход
                            </Button>
                        </Form.Item>
                        <Form.Item wrapperCol={{sm: {offset: 8, span: 16,}}} style={{opacity: error===401&&!log ? 1 : 0}}>
                            <div style={{color: 'red'}}>Неправильный логин или пароль</div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
};



