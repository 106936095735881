import axios from "axios";
import {HOST_POINT} from "../common/constants";
import {RequestPointType} from "../common/types";

const instance = axios.create({
    baseURL: HOST_POINT,
    headers: {
       "Content-Type": "application/json"
    }
})

export const pointApi = {
    getPoints(){
        return instance.get('/depots')
    },
    getPointId(id: string){
        return instance.get(`/depots/${id}`)
    },
    getDetailsPointId(id: string, data: RequestPointType){
        return instance.post(`/depots/${id}/details`, data)
    }
}