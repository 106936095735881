export const HOST = 'https://api.report-online.ru/api/v1' /*'http://79.174.82.170:8000/api/v1'*/

export const HOST_GEO = 'http://193.178.170.127:8156/b-geo'
export const HOST_POINT = 'http://vm721214.vps.masterhost.tech:8080'
export const HOST_NEWS = 'http://193.178.170.127:8118/api/v1'

export const MAIN = '/'
export const AUTH = '/auth'
export const CHARTS = '/charts'
export const MAP = '/map'
export const NEWS = '/news'
export const INFO_POINT = `${MAP}/:id`

export const HEIGHT = 560     // высота графика
export const DELTA = 13     // максимальное изменение меток

export const FONT_SIZE_DATA_LABELS = '12px'

/*_________________________________________coefficients__________________________________*/

export const WB_AKB_FACTOR = 18000000
export const WB_AKB_FACTOR_MIN = 7000000
export const OZ_AKB_FACTOR = 4000000
export const SAM_AKB_FACTOR = 700000
export const CATALOG_FACTOR = 40000000
