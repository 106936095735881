/*export const buttonsData = ['Рынок, темпы', 'Рынок, руб', 'АКБ %', 'АКБ', 'Моб. П', 'Рост рынка',  'Доли уч. %']*/
export const buttonsData = [
    {value: 'Рынок, темпы', label: 'Рынок, темпы'},
    {value: 'Рынок, руб', label: 'Рынок, руб'},
    {value: 'АКБ %', label: 'АКБ, %'},
    {value: 'АКБ', label: 'АКБ'},
    {value: 'Каталог', label: 'Количество товаров, каталог'},
    //{value: 'Каталог1', label: 'Количество товаров, каталог'},
]
/*export const buttonsDataCategories = ['Категории', 'Сравнение общее', 'Данные 1 уровня', 'Данные, товары', 'Данные, %']*/
export const buttonsDataCategories = [
    {value: 'Категории', label: 'Категории'},
    {value: 'Сравнение общее', label: 'Сравнение общее'},
    {value: 'Данные 1 уровня', label: 'Данные 1 уровня'},
    {value: 'Данные, товары', label: 'Данные, товары'},
    {value: 'Данные, %', label: 'Данные, %'},

]

export const buttonsDataPrice = [
    {value: 'PI фактический', label: 'PI фактический'},
    {value: 'PI витринный', label: 'PI витринный'},
    {value: 'Доля акций', label: 'Доля акций'},
    {value: 'Глубина скидки', label: 'Глубина скидки'},
]