export type ColorsObjectType = {
    name: string
    color: string
}

export const marketPlacesColors: ColorsObjectType[] = [
    {name: 'Рынок', color: '#808080'},
    {name: 'Wildberries',  color: '#C811aa'},
    {name: 'Ozon', color: '#0059FF'},
    {name: 'AliExpress',  color: '#e74e0a'},
    {name: 'Яндекс.Маркет',  color: '#ffe700'},
    {name: 'Мегамаркет', color: '#24b23e'},
    {name: 'Доля E-grocery', color: '#24b23e'},
    {name: 'Рынок млрд', color: '#ECE611'},
    {name: 'Сбер Итого', color: '#23f800'},
    {name: 'Яндекс Итого', color: '#ff8400'},
    {name: 'СберМегаМаркет Gross', color: '#65b802'},
    {name: 'Яндекс.Маркет (Сплит)', color: '#bb92ba'},
    {name: 'Ya.M SOV', color: '#ffe700'},
    {name: 'OZON SOV', color: '#0059FF'},
    {name: 'SMM SOV', color: '#24b23e'},
    {name: 'WB SOV', color: '#C811aa'},
   // {name: 'MM SOV', color: '#24b23e'},

]

export const superMarketsColors: ColorsObjectType[] = [
    {name: 'Рынок',  color: '#808080'},
    {name: 'Магнит.Доставка',  color: '#e30613'},
    {name: 'Магнит',  color: '#e30613'},
    {name: 'Самокат',  color: '#ff335f'},
    {name: 'WB Food',  color: '#C811aa'},
    {name: 'Ozon Food', color: '#0059FF'},
    {name: 'Яндекс.Лавка',  color: '#00bfdf'},
    {name: 'Яндекс Лавка',  color: '#00bfdf'},
    {name: 'Яндекс.Маркет15',  color: '#ffe700'},
    {name: 'Ozon fresh',  color: '#0059FF'},
    {name: 'Пятёрочка',  color: '#ff7c80'},
    {name: 'Яндекс.Еда',  color: '#d6af2c'},
    {name: 'ВкусВилл', color: '#179c49'},
    {name: 'Перекресток.Доставка', color: '#005a27'},
    {name: 'Перекресток.Впрок', color: '#95c93d'},
    {name: 'Пятерочка.Доставка', color: '#ff7c80'},
    {name: 'Купер', color: '#89AC76'},
    {name: 'X5', color: '#ff7c80'},
    {name: 'X5 SOV', color: '#ff7c80'},
    {name: 'Купер SOV', color: '#89AC76'},
    {name: 'Самокат SOV', color: '#ff335f'},
    {name: 'Я.Лавка SOV', color: '#00bfdf'},
    {name: 'Вкусвилл SOV', color: '#179c49'},
    {name: 'Магнит SOV', color: '#e30613'},
    {name: 'Рынок млрд', color: '#ECE611'},
]

export const colorsCategories = [
    '#007934',
    '#FF6700',
    '#800000',
    '#DCFF00',
    '#12127D',
    '#FF3E6F',
    '#058789',
    '#F17950',
    '#00F4FF',
    '#D2C04B',
    '#C7307B',
    '#E4DFEC',
    '#41733A',
    '#85B80F',
    '#FF6700',
    '#795E20',
    '#A3EFC0',
    '#00F4FF',]