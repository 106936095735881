import React from 'react';

import styles from './logo.module.sass'

import image from '../../../assets/logo.svg'

export const Logo = () => {
    return (
        <div className={styles.main}>
            <div >
                <img src={image} alt='logo' className={styles.image}/>
            </div>
        </div>
    );
};

