import React, {memo} from 'react';
import Checkbox from "antd/es/checkbox";
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {CheckboxValueType} from "antd/es/checkbox/Group";

import styles from './marketPlaces.module.sass'
import {selectMarketPlacesCheckBoxAC, sharesCheckedAC} from "../../../redux/optionsReducer";
import {
    choiceOfPlatform,
    selectChart,
    selectDataName,
    selectMarketPlaces,
    selectMps,
    selectNamesForCatalog,
    selectSwitchCategories, selectTableChartCategories
} from "../../../common/selectors";
import {marketPlacesOptions, second} from "../../../common/optionsForCheckBoxes";
import {selectMpsAC} from "../../../redux/categoriesReducer";


export const MarketPlaces = memo(() => {

    const dispatch = useAppDispatch()

    const chart = useAppSelector(selectChart)
    const marketplaces = useAppSelector(selectMarketPlaces)
    const dataNames = useAppSelector(selectDataName)
    const dataCatalogNames = useAppSelector(selectNamesForCatalog)
    const categories = useAppSelector(selectSwitchCategories)
    const platform = useAppSelector(choiceOfPlatform)
    const tableCharts = useAppSelector(selectTableChartCategories)
    const mps = useAppSelector(selectMps)

    const onChange = (checkedValues: CheckboxValueType[]) => {
        dispatch(selectMarketPlacesCheckBoxAC(checkedValues))
        dispatch(sharesCheckedAC(checkedValues))
    };

    let filter: string[] = dataNames.filter((el) => el.indexOf('SOV') === -1)
    let filteredMpsOptions = marketPlacesOptions.filter((el) => filter.includes(el.value))
    let filteredCatalogOptions = marketPlacesOptions.filter((el) => dataCatalogNames.includes(el.value))

    const onChangeCategories = (checkedValues: CheckboxValueType) => {
        dispatch(selectMpsAC(checkedValues))
    };

    const func = () => {
        return marketPlacesOptions.map((el, index) => index <= 5 && index > 0 && <Checkbox  key={index} className={styles.item} onChange={() => onChangeCategories(el.value)} value={el.value} checked={el.value === mps}>{el.label}</Checkbox>)
    }
    const mpsCheckBoxes = func()

    const getOptionsContent = () => {
        if (platform === 'categories' && chart === 'Категории') {
            return <div className={styles.checkbox}>
                {mpsCheckBoxes}
            </div>
        }
        return <Checkbox.Group onChange={onChange} defaultValue={marketplaces} >
            <div className={styles.checkbox}>
                {(chart === 'Каталог' || chart === 'Каталог1' || (chart === 'Данные 1 уровня' && tableCharts !== '') ? filteredCatalogOptions : filteredMpsOptions).map((el, index) => <Checkbox key={index} style={second.includes(el.value)?{color: '#808080'}: {color: 'white'}}  value={el.value} className={styles.item}>
                    <div className={styles.label} >{el.label}</div>
                </Checkbox>)}
            </div>
        </Checkbox.Group>
    }


    return (
        <div className={styles.main}>
            {getOptionsContent()}
        </div>
    )
})

