import {ColorsObjectType} from "../optionsForColors";
import {dataType} from "../types";

export const findColor = (option: ColorsObjectType[], data: dataType[], colors: string[]) => {
     return data&&data.forEach((el) => {
            option.forEach((item) => el.name === item.name && colors.push(item.color))
        })
}

export const fillForColumns = (chart: string, fill: string[]) => {
    return chart === 'Рост рынка' ?
        {
            type: 'gradient',
            gradient: {
                type: "vertical" as 'vertical' | 'horizontal',
                shadeIntensity: 0.5,
                gradientToColors: fill,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 75],
                colorStops: []
            },
        }:  {
            type: '',
        }
}