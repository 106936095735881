import {dataType} from "../types";
import {superMarketsColors} from "../optionsForColors";
import {FONT_SIZE_DATA_LABELS} from "../constants";

export const annoPrice = (data: dataType[], clickAnno: any[]) => {
    let numb: number[] = []
    data.map((el) => {
        el.data.forEach((item) => {
            numb.push(item[1])
        })
    })
    let max = Math.max(...numb)
    let min = Math.min(...numb)

    let points: any = []
    let dateOfChart: number[] = []
    let background: string = ''
    data.forEach((el) => {
        el.data.forEach((item) => !dateOfChart.includes(item[0]) ? dateOfChart.push(item[0]) : item)

    })

    data.forEach((el, index) => {
        el.data.forEach((item) => {
            superMarketsColors.find((i) => {
                return i.name === el.name ? background = i.color : background = ''
            })
            points.push({
                x: item[0],
                y: item[1],
                yAxisIndex: 0,
                marker: {
                    size: 0
                },
                label: {
                    offsetY: 0,
                    text: clickAnno.includes(index) || item[1] === null ? '' : item[1],
                    textAnchor: 'middle',
                    borderRadius: 10,
                    borderWidth: .2,
                    style: {
                        background,
                        color: background === '#ffe700' ? '#202020' : '#F5F5F5',
                        fontSize: FONT_SIZE_DATA_LABELS,
                        fontWeight: 600,
                        padding: {
                            left: 5,
                            right: 5,
                            top: 0,
                            bottom: 2,
                        }
                    }
                }
            })
        })
    })
    let yAxis: any = []
    dateOfChart.forEach(el => yAxis.push([]))
    points.forEach((el: any) => {
        dateOfChart.forEach((date, index) => (
            date === el.x ? yAxis[index].push(el) : el
        ))
    })
    let deltaChart: number = max - min
    yAxis.forEach((point: any) => {
        point.sort((a: any, b: any) => a.y - b.y)
        for (let i: number = 1; i < point.length; i++) {
            let px = deltaChart/280
            let deltaUnit = px*12
            let difference = point[i].y - point[i - 1].y
            if (difference < deltaUnit) {
                point[i].label.offsetY -= 12 - difference/(px)
            }
            if (point[i - 1].label.offsetY < 0 && difference < deltaUnit - point[i - 1].label.offsetY * px/2) {
                point[i].label.offsetY += /*difference <= deltaUnit ? */point[i - 1].label.offsetY /*: point[i - 1].label.offsetY + 5*/
            }
        }
    })
    return points
}