

export const title = [
    {name: 'Рынок, темпы', title: 'Рынок, темпы'},
    {name: 'Рынок, руб', title: 'Рынок, руб.'},
    {name: 'АКБ %', title: 'Активная клиентская база %'},
    {name: 'АКБ', title: 'Активная клиентская база'},
    {name: 'Моб. П', title: 'Скачивание мобильного приложения'},
    {name: 'Рост рынка', title: 'Объем и рост рынка, млрд руб.'},
    {name: 'Доли уч. %', title: 'Доли участников %'},
    {name: 'Каталог', title: 'Количество товаров, каталог, млн шт.'},
    //{name: 'Каталог1', title: 'Количество товаров, каталог, млн шт.'},
]

export const titleCategories = [
    {name: 'Категории', title: 'Количество товаров, доли категорий'},
    {name: 'Сравнение общее', title: 'Сравнение данных'},
]

export const titlePrice = [
    {name: 'PI фактический', title: 'Показатель 1: PI фактический'},
    {name: 'PI витринный', title: 'Показатель 2: PI витринный'},
    {name: 'Доля акций', title: 'Показатель 3: Доля акций'},
    {name: 'Глубина скидки', title: 'Показатель 4: Глубина скидки'},
]