import React from 'react';
import ReactApexChart from "react-apexcharts";

import styles from './categoriesCharts.module.sass'
import {useAppSelector} from "../../../../redux/store";
import {selectCategories, selectMps, selectTimeForCategories} from "../../../../common/selectors";
import {colorsCategories} from "../../../../common/optionsForColors";
import {formatGrade} from "../../../../common/optionsForCategories/table";


export const CategoriesCharts = () => {


    const catData = useAppSelector(selectCategories)
    const mps = useAppSelector(selectMps)
    const timeCat = useAppSelector(selectTimeForCategories)

    let data: number[] = []
    const dataCategories = catData&&[...catData]
    dataCategories&&dataCategories.sort((a,b) => b.count_products - a.count_products)
    dataCategories&&dataCategories.map((el) => data.push(el.count_products))
    const dataForPie: number[] = []
    data&&data.forEach((el, index) => index < 10 && dataForPie.push(el))
    let cat: string[] = []
    dataCategories&&dataCategories.map((el) => cat.push(el.name_category))

    const carForPie = cat.filter((el, i) => i < 10)
    const seriesColumn = [{
        name: "products",
        data
    }]
    const optionsColumn = {
        chart: {
            type: 'bar' as const,
            height: 380,
            toolbar: {
                show: false
            },

        },
        colors: colorsCategories,
        plotOptions: {
            bar: {
                distributed: true,
                colors: {

                }
            }
        },
        xaxis: {
            type: 'category' as const,
            categories: cat,
            labels: {
                rotate: -75,
                trim: true,
                //maxHeight: 150,
                style: {
                    colors: 'white'
                }

            },
        },
        yaxis: {
            //max: seriesColumn[0].data[0] * 1.05,
            tickAmount: 5,
            labels: {
                style: {
                    colors: 'white'
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        title: {
            style: {
                color: 'white'
            },
            text: 'Количество товаров в каждой категории 1 уровня',
        },
        tooltip: {
            theme: 'dark',
            y: {
                formatter: (value: number) => formatGrade(value),
            },
        },
    }

    const series = dataForPie
    const options = {
        chart: {
            width: 380,
            type: 'donut' as const,
        },
        colors: colorsCategories,
        stroke: {
            width: 5,
            colors: ['#202020']
        },
        plotOptions: {
            pie: {
                //offsetY: 100,
                startAngle: -80,
                endAngle: 280,
                dataLabels: {
                    offset: 5,
                    minAngleToShowLabel: 0,

                },
                donut: {
                    size: '40%',
                    labels: {
                        show: true,

                        name: {
                            show: true,
                            color: 'white'
                        },
                        value: {
                            color: 'white',
                            formatter: function (val: string) {
                                return val.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                            }
                        }
                    }
                }
            },

        },
        labels: carForPie,

        title: {
            text: `Доля ТОП-10 категорий ${timeCat[0]} / ${timeCat[1]}` ,
            style: {
                color: '#F5F5F5'
            }
        },
        legend: {
            show: true,
            position: 'right' as const,
            width: 200,
            height: 315,
            labels: {
                colors: '#F5F5F5'
            },

        },
        tooltip: {
          enabled: false
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    //width: 200
                },
                legend: {
                    show: false
                }
            }
        }]
    }

    const info = dataCategories.find((el) => el.spm === mps)


    return (
        <div className={styles.main}>
            <div className={styles.charts}>
                <div className={styles.chart}>
                    <ReactApexChart options={optionsColumn} series={seriesColumn} type="bar" height={350}/>
                </div>
                <div className={styles.chart}>
                    <ReactApexChart options={options} series={series} type="donut" height={320}  styles={{color: 'white', marginBottom: 20}}/>
                </div>
            </div>
            <div className={styles.info}>
                <div><span>Общее количество позиций в каталоге: </span><h3>{info && formatGrade(info!.count_positions) + ' товаров'}</h3></div>
                <div><span style={{fontWeight: 600, fontSize: 20}}>{info&&formatGrade(info!.count_unic_category)}</span><span> - уникальных категорий</span></div>
                <div>в том числе:</div>
                <ul>
                    <li>{'1 уровень -' + (info&&info!.level_1)}</li>
                    <li>{'2 уровень -' + (info&&info!.level_2)}</li>
                    <li>{'3 уровень -' + (info&&info!.level_3)}</li>
                    <li>{'4 уровень -' + (info&&info!.level_4)}</li>
                    <li>{'5 уровень -' + (info&&info!.level_5)}</li>
                </ul>
                <h3>Категории 1 уровня:</h3>
                {cat.map((el, index) => <span key={index}>{el+', '}</span>)}
            </div>
        </div>
    );
};

