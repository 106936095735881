export const orderData = [
    'Рынок',
    'Wildberries',
    'Ozon',
    'Яндекс.Маркет',
    'AliExpress',
    'Мегамаркет',

    'WB SOV',
    'OZON SOV',
    'Ya.M SOV',
    'SMM SOV',

    'Самокат',
    'Яндекс.Лавка',
    'Яндекс Лавка',
    'Яндекс.Еда',
    'ВкусВилл',
    'Купер',
    'Перекресток.Доставка',
    'Перекресток.Впрок',
    'X5',
    'Пятерочка.Доставка',
    'Магнит',
    'Магнит.Доставка',
    'Ozon fresh',
    'WB Food',
    'Ozon Food',

    'Самокат SOV',
    'Я.Лавка SOV',
    'Купер SOV',
    'X5 SOV',
    'Магнит SOV'
]